import React from "react"

import './component_styles/contentLoader.scss'

export default function ContentLoader(){
    return(
        <div className="content-loader-container">

            <div className="wrapper">
                <div className="circle"></div>
                <div className="circle"></div>
                <div className="circle"></div>
                <div className="shadow"></div>
                <div className="shadow"></div>
                <div className="shadow"></div>
            </div>
            <span className="loader-text">
                <p>
                    Bạn chờ chút nha!
                </p>
            </span>
        </div>
    )
}

