import React from "react";
import { Link } from "react-router-dom";

import '../components-render/component_styles/productPreviewBox.scss'

// import { BsFillHeartFill } from 'react-icons/bs'
import { IoEyeSharp } from 'react-icons/io5'
import { IoIosArrowForward } from "react-icons/io";


export default function ProductPreviewBox({product,index,isLoading}){
    // console.log(product.img.split(";").length);
    let ProductPath=window.location.pathname==="/product"?"":"../product";
    // console.log(isProductPath);

    const unidecode =require('unidecode') //Bỏ dấu trong tiếng Việt
    
    // console.log(isLoading);

    if(isLoading===true){
        return(
            <div className="product-item-wrapper" key={index} >
                <div className="product-avt skeleton">
                </div>
            
                <div className='product-infor'>
                    <div className='product-name skeleton'>
                        {/* {product.name} */}
                    </div>
                    <div className='product-detail'>
                        
                        <div className='unit-price-loading skeleton'>
                            {/* {product.price} */}
                        </div>

                        <div className='number-of-favorites-box skeleton'>
                        </div>

                    </div>

                </div>
                <div className="view-detail">
                    <IoIosArrowForward/>
                </div>
            </div>
        )
    }
    return (
        <Link 
            to={`${ProductPath}?category=${unidecode(product?.category?.toLowerCase()?.replace(/\s+/g, '')|| "none")?.replace(/[^a-zA-Z0-9]+/g, '')}&productId=${product?.id}`} 
            className="product-item-wrapper" key={index} 
        >
            
            <div className="product-avt">
                <img 
                    className='product-img' 
                    src={product?.avt?.imgSrc} 
                    alt='card'>
                    {/* onmouseover="this.src='LibraryHoverTrans.png';" onmouseout="this.src='LibraryTransparent.png';" */}
                </img>
                <img
                    className="hover-gif"
                    src={product?.avt_hover?.imgSrc}
                    alt="gif">
                </img>
            </div>
                
            <div className='product-infor'>
                <div className='product-name'>
                    {product.name}
                </div>
                <div className='product-detail'>
                    <div className='unit-price'>
                        {product.price}
                    </div>

                    <div className='number-of-favorites-box'>
                        <IoEyeSharp className='favorite-icon' />
                        <div className='number-of-favorites'>
                        {product.seen}
                        </div>

                    </div>

                </div>
            </div>
            <button className="view-detail">
                <IoIosArrowForward/>
            </button>
        </Link>
        
    )
    
    
}