import React from "react";
import ProductPreviewBox from "./ProductPreviewBox";

import "./component_styles/relationProducts.scss"


export default function RelationProduct({relationProducts,currentProduct}){

    return(
        <div className="relation-product">
            <div className="relation-products-title">
                <hr/>
                <div className="title">
                    <h1 className="main-title">Có thể bạn sẽ thích</h1>
                    <h4 className="sub-title">Các sản phẩm liên quan với {currentProduct?.name}</h4>
                </div>
                <hr/>
            </div>
            <div className="relation-products-list">
                {relationProducts?.filter(item=>item.category===currentProduct?.category||item.category==="Card bo góc").map((item, index) => {
                    // console.log(item.id!==currentProduct.id);
                //     if(window.screen.width<= 576){
                //         if (index % 2 === 0) {
                //             return (
                //                 <div className="row-item" key={index}>
                //                     <ProductPreviewBox product={item} index={index} />
                //                     {relationProducts[index + 1] && (
                //                         <ProductPreviewBox product={relationProducts[index + 1]} index={index + 1} />
                //                     )}                                    
                //                 </div>
                //                 );
                //             }
                //     }else{

                //         if (index % 3 === 0) {
                //         return (
                //             <div className="row-item" key={index}>
                //                 <ProductPreviewBox product={item} index={index}  />
                //                 {relationProducts[index + 1] && (
                //                     <ProductPreviewBox product={relationProducts[index + 1]} index={index + 1} />
                //                 )}
                //                 {relationProducts[index + 2] && (
                //                     <ProductPreviewBox product={relationProducts[index + 2]} index={index + 2}  />
                //                 )}
                                
                //             </div>
                //             );
                //         }
                //     }
                //     return null;

                    return(
                        <ProductPreviewBox product={item} index={index}  />
                    )
                })}
            </div>
        </div>
    )
}