export function GetDecodedToken(localStorageName){
    const data = JSON.parse(localStorage.getItem(localStorageName));
    const token = data?JSON.parse(data?.token):null; 
    // console.log("In Token controller: "+token);
    let decodedToken=null;
    
    if(token!==null){
        
        decodedToken = JSON.parse(atob(token?.split(".")[1]));
    }
    return decodedToken
}

export function GetToken(localStorageName){
    const data = JSON.parse(localStorage.getItem(localStorageName));
    const token = data?JSON.parse(data?.token):null; 
    // console.log("In Token controller: "+token);

    return token
}