import React,{useEffect,useState,useRef,useContext} from 'react';

import GetAllSlides from '../controllers/ImageSliderController';

//import style
import './component_styles/imageSlider.scss'
import { AppContext } from '../../Context/AppProvider';
import { Link } from 'react-router-dom';

const delay = 3000;

export default function ImageSlider() {

    const [slides, setSlides] = useState(null)
    useEffect(()=>{
        GetAllSlides(setSlides);
    },[])

    const {products} = useContext(AppContext)
    // console.log(slides);

  const [index, setIndex] = useState(0);
  const timeoutRef = useRef(null);

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }
  // console.log(slides);

  useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(
      () =>
        setIndex((prevIndex) =>
          slides?
            prevIndex >= (slides?.filter(slide=>slide.status==="release").length - 1) ? 0 : prevIndex + 1
          :
            0
          
        ),
      delay
    );

    return () => {
      resetTimeout();
    };
  }, [index]);
  if(!products || products?.length<=0){
    return(
      <div className="slideshow">
      <div
        className="slideshowSlider"
        style={{ transform: `translate3d(${-index * 100}%, 0, 0)` }}
      >
        <div className='slide skeleton'>

        </div>
      </div>
    </div>
    )
  }
  return (
    <div className="slideshow">
      <div
        className="slideshowSlider"
        style={{ transform: `translate3d(${-index * 100}%, 0, 0)` }}
      >
        {slides?.filter(slide=>slide.status==="release")// show all slide which releasing
                .sort((a,b)=>a.index-b.index)   //sort by attribute index ascending
                .map((backgroundImage, index) => (
          <Link 
            to={`./product?category=&productId=${backgroundImage.productId?backgroundImage.productId:100000}`}
            className='slide'>
          
            <img
              
              src={backgroundImage?.img.imgSrc} alt={backgroundImage?.img.imgSrc}
              style={{ backgroundImage }}
              // onClick={()=>handleClick(backgroundImage.productId)}
            />
          </Link>

        ))}
      </div>

      <div className="slideshowDots">
        {slides?.filter(slide=>slide.status==="release")// show all slide which releasing
                .sort((a,b)=>a.index-b.index)   //sort by attribute index ascending
                .map((_, idx) => (
          <div
            key={idx}
            className={`slideshowDot${index === idx ? " active" : ""}`}
            onClick={() => {
              setIndex(idx);
            }}
          ></div>
        ))}
      </div>
    </div>
  );
}