import React, { useState } from "react";
import DescriptionRender from "./DescriptionReder";


export default function ProductIntroduce({productInfor, productName}){

    const [contentRender, setContentRender] = useState("intro") 

    return (
        <div className="product-introduce">
            <div className="infor-bar-controller">
                <div className={contentRender==='intro'?"infor-bar-title-active":"infor-bar-title"} onClick={()=>setContentRender('intro')}>
                    <h3>

                        GIỚI THIỆU SẢN PHẨM
                    </h3>
                </div>
                <div className={contentRender==='notice'?"infor-bar-title-active":"infor-bar-title"} onClick={()=>setContentRender("notice")}>
                    <h3>

                        LƯU Ý KHI ĐẶT HÀNG
                    </h3>
                </div>
                {/* <div className="infor-bar-title">Thông tin sản phẩm</div> */}
                {/* <div className="infor-bar-title">Thông tin sản phẩm</div> */}
            </div>
            <div className="infor-tab-content">

                {
                    contentRender === "intro" && 
                        <>
                        
                            <div className="product-introduce-title">
                                <hr/>
                                <h1>{productName?.name}</h1>
                                <hr/>
                            </div>

                            <div className="description">
                                <DescriptionRender 
                                    description_data={
                                        productInfor.description
                                    } 

                                    numberic={false}
                                />
                            </div>

                            <div className="options-description">
                                <div className="material-des">
                                    <div className="material-des-title">
                                        <h3>

                                            Chất liệu:
                                        </h3>
                                    </div>
                                    {productInfor.option_des.material_des.map(material=>{
                                        return(
                                            <DescriptionRender 
                                            description_data={material} 
                                            numberic={false}
                                            imgSrc={productName?.img}/>
                                        )
                                    })}
                                    
                                </div>
                                <div className="effect-des">
                                    <div className="effect-des-title">
                                        <h3>

                                            Hiệu ứng:
                                        </h3>
                                    </div>
                                    {productInfor.option_des.effect_des.map(effect=>{
                                        return(
                                            <DescriptionRender 
                                                description_data={effect} 
                                                numberic={false}
                                                imgSrc={productName?.img}
                                            
                                            />
                                        )
                                    })}
                                    
                                </div>
                            </div>
                        </>
                }

                {
                    contentRender === "notice" && 
                        <>

                            <div className="notify">
                                
                                {productInfor.order_notify.map((notify,index)=>{
                                    return(

                                        <DescriptionRender 
                                        description_data={notify} 
                                        numberic={index} 
                                        imgSrc={productName?.img}
                                        />
                                    )
                                })}
                            </div>

                        </>
                }
            
            </div>    
            
        </div>
    )
}