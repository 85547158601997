import React, {useEffect, useContext}  from 'react';
import '../styles/config/content.scss'
import '../styles/product.scss'
// import * as process from '../models/env'

//import icon

// import {FaListAlt} from 'react-icons/fa'
import ProductPreview from '../components-render/ProductPreview';
import BasicParamsRender from '../components-render/BasicParamsRender';
import ProductOptionRender from '../components-render/ProductOptionRender';
import ProductIntroduce from '../components-render/ProductIntroduce';
import PrintAre from '../components-render/PrintingArea';
// import Question from '../components-render/Questions';
import RelationProduct from '../components-render/RelationProduct';
// import OrderInfor from '../components-render/OrderInfor';
// import { products } from '../models/data';
import CommentsArea from '../components-render/CommentsArea';
// import {GetAllProducts} from '../controllers/ProductContoller';
import { AppContext } from "../../Context/AppProvider";
import { useLocation } from 'react-router-dom';


const productInforSticker={
  description:{
    title:"Card bo góc là gì?",
    content:"Thẻ nhựa hay còn gọi là PVC là loại thẻ được làm từ nhựa, rất được ưa chuộng vì tính tiện dụng, độ bền cao và giá thành rẻ. Ngày nay, thay vì dùng thẻ giấy với giá in khá đắt nhưng không bền, người ta thường chuyển từ in thẻ giấy sang in thẻ nhựa để sử dụng thuận tiện hơn. /br/ Thẻ nhựa hay còn gọi là PVC là loại thẻ được làm từ nhựa, rất được ưa chuộng vì tính tiện dụng, độ bền cao và giá thành rẻ. Ngày nay, thay vì dùng thẻ giấy với giá in khá đắt nhưng không bền, người ta thường chuyển từ in thẻ giấy sang in thẻ nhựa để sử dụng thuận tiện hơn.",
  },

  option_des:{
    material_des : [
      {
        title: "Card Giấy C300",
        content: "Đây là loại thẻ được làm từ chất liệu giấy C300. Thẻ giấy C300 có độ bền tương đối và giá thành phải chăng./br//br/Ưu điểm:/br/- Giá thành phải chăng, phù hợp cho thẻ tạm thời, dễ tái chế./br//br/Nhược điểm:/br/- Không chống nước, độ bền không cao bằng các loại thẻ nhựa khác.",
        img: "../img/imgs_preview_banner/pic_11.jpg"
      },
     
    ],
    
    effect_des : [
      {
        title: "Bóng",
        content: "Hiệu ứng bóng tạo cho thẻ một vẻ ngoài sáng bóng và bắt mắt. /br/ Thẻ với hiệu ứng bóng thường được sử dụng trong thẻ VIP, thẻ thành viên, thẻ quà tặng, v.v.",
        img: "../img/imgs_preview_banner/pic_16.jpg"
      },
      {
        title: "Mờ",
        content: "Hiệu ứng mờ tạo cho thẻ một vẻ ngoài mịn màng và tinh tế. /br/ Thẻ với hiệu ứng mờ thường được sử dụng trong thẻ công ty, thẻ nhân viên, thẻ tạm thời, v.v.",
        img: "../img/imgs_preview_banner/pic_17.jpg"
      },
      {
        title: "Hologram",
        content: "Hiệu ứng hologram tạo cho thẻ một vẻ ngoài lấp lánh và độc đáo. /br/ Thẻ với hiệu ứng hologram thường được sử dụng trong thẻ an ninh, thẻ giảm giá, thẻ thành viên cao cấp, v.v.",
        img: "../img/imgs_preview_banner/pic_18.jpg"
      },
    ]
  },
  order_notify :[
    {
      title: "Quy trình đặt hàng",
      content: "",
      img:'../img/thegioiinan-quytrinh.png'
    },
    {
      title: "Thông tin về sản phẩm và file in",
      content:
        "Shop ảnh nổi khuyến khích sử dụng hệ màu CMYK để thiết kế./br/Thành phẩm sẽ có dung sai ±1 mm nên Shop ảnh nổi khuyến khích anh chị thiết kế viền lớn hơn 3mm và khi thành phẩm sẽ có sự chênh lệch giữa các cạnh ±1 mm./br/Shop ảnh nổi chấp nhận file thiết kế xuất từ các phần mềm Adobe Illustrator (Ai), Photoshop (Psd), Indesign (Indd) và Corel (Cdr), xem thêm hướng dẫn xuất file tại đây./br/Các định dạng file có đuôi: psd, tiff, jpg, png… phải đạt độ phân giải 300dpi đối với hình ảnh và 400dpi đối với text./br/Đối với in offset, nếu file của anh chị có nền in màu đen, hãy chỉnh màu C: 20 M: 20 Y: 0 K: 100, nếu file của anh chị có chữ đen, hãy chỉnh màu C: 20 M: 0 Y: 0, K: 100./br/Đối với in kỹ thuật số, nếu file của anh chị có nền in màu đen, hãy chỉnh màu C: 20 M: 0 Y: 0 K: 100",
      img:'../img/he_mau.jpg'
     },
    {
      title: "Thanh toán & in ấn",
      content:
        "Để Shop ảnh nổi tiến hành xử lý đơn hàng, anh chị vui lòng đặt cọc 50% chi phí in ấn và 100% phí thiết kế (nếu anh chị yêu cầu Shop ảnh nổi thiết kế file riêng)./br/Nếu file của anh chị không in ấn được, Shop ảnh nổi sẽ hoàn trả lại số tiền mà anh chị đã đặt cọc./br/Anh chị vui lòng kiểm tra nội dung file in trước khi xác nhận, Shop ảnh nổi có trách nhiệm thực hiện đúng nội dung mà anh chị đã xác nhận./br/Thời gian in ấn sẽ được tính từ khi anh chị xác nhận file in"
    },
    {
      title: "Giao nhận & đổi trả",
      content:
        "Thời gian giao hàng sẽ được tính từ khi đơn hàng thành phẩm./br/Đối với anh chị có địa chỉ giao hàng ở ngoài TPHCM, thời gian giao hàng còn phụ thuộc vào đơn vị vận chuyển thứ 3./br/Nếu sản phẩm bị lỗi do kỹ thuật in, sản phẩm không đúng với các yêu cầu khi đặt hàng, Anh chị vui lòng liên hệ với Shop ảnh nổi trong vòng 05 ngày kể từ khi nhận hàng để được hỗ trợ xử lý./br/Shop ảnh nổi có trách nhiệm đổi trả sản phẩm cho anh chị nếu sản phẩm bị lỗi, Shop ảnh nổi không áp dụng quy đổi sản phẩm thành tiền để hoàn trả"
    }
  ]
  
  
}

const productOptions = {
  ma_ef: [
    ["Card Giấy C300", "bóng", "mờ", "Hologram", "kim tuyến"],
    ["Card Cứng 4k", "bóng", "lụa", "Hologram", "kim tuyến"],
    ["Card Nhựa PVC", "bóng", "nhám", "Hologram", "kim tuyến"],
    ["Card Polar"],
    ["Card Lenti"]
  ],
  size: [
    "60x90 mm",
    "85x55 mm",
    "54x86mm",
    "60x60mm",
    "90x54mm",
    "50x150mm"
  ],
  price: {
    unlimitedDesigns: [  
      { quantity: "10", price: "3" },
      { quantity: "50", price: "2.5" },
      { quantity: "100", price: "2.2" },
      { quantity: "300", price: "2" },
      { quantity: "Trên 500", price: "1.5" },
    ],
    limitedDesigns: {
      limit: "5",
      price_table:[  
        { quantity: "100", price: "2" },
        { quantity: "300", price: "1.5" },
        { quantity: "Trên 500", price: "1.2" },
      ], 
    },

    extra:[
      {
        add:"Cán Hologram:",
        price: "Thêm 0.5 k/cái"
      },
      {
        add:"Cán Kim tuyến:",
        price: "Thêm 1 k/cái"
      }
    ],


  }


};


const printArea={
  img: 
  ['../img/example_printing_area_v1.jpg',
    '../img/example_printing_area_v2.jpg']
}

function Product() {

  //Get product from product_id
  let product={}
  const {products} = useContext(AppContext)

  const location = useLocation()
  const searchParams= new URLSearchParams(location.search)
  const product_id = searchParams.get("productId")

  useEffect(()=>{
    product=products?.find((product)=>product.id===+product_id)
  },[product_id])

  if (!products || !products.length) {
    // Display a loading indicator or handle the absence of data
    return <div>Loading...</div>;
  }else{
    product=products?.find((product)=>product.id===+product_id)
  }

  // console.log(products);

  return (
    <>
      <div className='main'>
        <div className='product-content'>
          
            <div className='post-render'>
      
              <ProductPreview product_preview={[product?.video,...product?.imgs]} productSeen={product?.seen}/>
              <BasicParamsRender product_infor={product}/>
              {/* <OrderInfor contactInfor={contactInfor} /> */}
              <ProductOptionRender size={null} productId={product_id}/>
              <ProductIntroduce productInfor={productInforSticker} productName={product}/>
              {
                product?.category==="Card bo góc"&&

                <PrintAre printArea={printArea}/>
              }
              {/* <Question questionSet={questionSet}/> */}
              <RelationProduct relationProducts={products?.filter(item=>item.id!==product.id)} currentProduct={product}/>
              {/* <OrderInfor contactInfor={contactInfor}/> */}
              <CommentsArea productId={product?.id}/>

            </div>
        </div>
        
      </div>
    </>
  );
}

export default Product;
