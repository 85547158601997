import React,{useState,useEffect,useContext } from "react";

import '../styles/cart.scss';
import OrderedProductDetail from "../components-render/OrderedProductDetail";
import { GetAllCart } from "../controllers/CartController";
import { Link } from "react-router-dom";
import RelationProduct from "../components-render/RelationProduct"
import {AppContext} from "../../Context/AppProvider"

function Cart(){

    const {products} = useContext(AppContext)

    const [cartList,setCartList]= useState([])
    const currentURL = window.location.href;
    const user_id = currentURL.match(/userId=(\d+)/)[1];
    useEffect(() => {
        GetAllCart(setCartList)
    }, []);

    // console.log(cartList);

    const [orderList,setOrderList] = useState([]);

    const handleRadioChange = (event,cart) => {
        if (event.target.checked) {
          setOrderList([...orderList, cart]); // Add the cart to orderList
        } else {
          setOrderList(orderList.filter((item) => item !== cart)); // Remove the cart from orderList
        }
        
      };

    //   console.log(orderList);
      
      
      const handleOrderClick = () => {
        localStorage.setItem('order', JSON.stringify(orderList));
        const queryParams = { 
            userId:user_id

        };
        const searchParams = new URLSearchParams(queryParams).toString();
        const url = `/order-confirmation?${searchParams}`;
    
        // Redirect to the specified URL
        window.location.href = url;
      };
    // console.log(cartList);
    return(
        <div className="main">
            <div className="cart-wrapper">

                <div className="cart-container">
                    <div className="cart-title">
                        <h2>Giỏ hàng của bạn</h2>
                    </div>
                    <div className="cart-list">
                        {
                            cartList===undefined||cartList?.length<=0||!cartList?"Bạn chưa có đơn hàng nào hết":
                            cartList?.map(cart=>{
                                // console.log(cart.cartId);
                                return(
                                    <label>
                                        <input
                                            type="checkbox"
                                            className="product-in-cart"
                                            name="product-in-cart"
                                            id="product-in-cart"
                                            onChange={(event) => handleRadioChange(event, { ...cart.productList[0], cartId: cart.cartId })}
                                        />
                                        <div className="product-in-cart-infor">

                                            <OrderedProductDetail product_infor={cart}/>
                                        </div>
                                    </label>
                                )
                            })
                        }
                    </div>   
                    <div className="cart-btns">
                        <button className="order" onClick={handleOrderClick} disabled={cartList===undefined||orderList.length===0}>
                            Đặt hàng
                        </button>
                        <button className="view-products">
                        <Link to="/"> Xem sản phẩm </Link>
                        </button>
                    </div>
                </div>
                
                <RelationProduct relationProducts= {products} currentProduct={"none"}/>
            </div>

            
        </div>
       
    )
}

export default Cart;