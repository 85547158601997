import React,{useContext} from "react"

import { RiHeartsFill } from 'react-icons/ri'

import { AppContext } from "../../Context/AppProvider";
import { Link } from "react-router-dom";

//import style
import './component_styles/hotProducts.scss'

export default function HotProducts() {

    // console.log(hotProducts);
  let products=[1,2,3,4,5,6,7]
  let isLoading=true;
    const {hotProducts} = useContext(AppContext)

    if (!hotProducts || !hotProducts.length) {
      // Display a loading indicator or handle the absence of data
      isLoading=true
    }else{
      isLoading=false
      products=hotProducts
    }
    // console.log(hotProducts);
    const month= new Date()
    return (
      <div className='trending-list-content' id='trending-list-content'>
        <div className='trending-list-title'>
          <RiHeartsFill className='trending-heart-icon' />
          Top
          <p className='high-light'>Trending</p>
          {
            month.getMonth()
          }
        </div>
        <div className='trending-list-container'>
  
          <div className='trending-list'>
  

            {products?.map((product,index) => (
              isLoading?
              <div className='trending-item-box' key={index}>
                <div className='sample-pic-with-rank skeleton'>
                  
                  
                </div>
                <div className='sample-infor'>
                  <p className='sample-name skeleton'>
                    {/* {product?.product.name} */}
                    Tên sản phẩm...
                  </p>
  
                  <ul className='options skeleton'>
                    <li>
                      {/* {product?.material} */}
                      Chờ chút...
                    </li>
                    <li>
                      {/* {product?.effect} */}
                      Chờ chút...

                    
                    </li>
                    <li>
                      {/* {product?.size} */}
                      Chờ chút...

                    </li>
  
                  </ul>
  
                </div>
              </div>
              :
              <Link 
                to ={`./product?category=&productId=${product.product.id}&material=${product?.material}&effect=${product?.effect}&size=${product?.size}`} 
                className='trending-item-box' key={index} >
                <div className='sample-pic-with-rank'>
                  <img 
                      className='sample-image' 
                      src= {product?.product.avt}
                      alt='card.jpg' />
                  <p className='rank'>{product?.rank}</p>
                </div>
                <div className='sample-infor'>
                  <p className='sample-name'>
                    {product?.product.name}
                  </p>
  
                  <ul className='options'>
                    <li>
                      {product?.material}
                    </li>
                    <li>
                      {product?.effect}
                    </li>
                    <li>
                      {product?.size}
                    </li>
  
                  </ul>
  
                </div>
              </Link>
  
            ))}
          </div>
        </div>
  
      </div>
    )
  
  }