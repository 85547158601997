// import instance from "../../axiosConfig";
import {getall, postcomment} from "../models/CommentsModel"
export async function GetAllComments(setDataFunc) {

  try {
    const response =await getall()
    setDataFunc(response)
  } catch (error) {
    console.log(error)
  }
  // const url =process.env.REACT_APP_GET_ALL_CONTACTS+"?pageSize=10000"
  // try {
  //   const response = await instance.get(url); // Sử dụng instance đã tạo
  //   setDataFunc(response.data.data);
  // } catch (error) {
  //   console.error(error);
  // }
}

export async function PostComment (userId,productId,content,rating,date){
  try {
    const response =await postcomment(userId,productId,content,rating,date)
  } catch (error) {
    console.log(error);
  }
}
