import React, { useEffect } from 'react';

const FBChatPlugin = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.async = true;
    script.defer = true;
    script.crossOrigin = 'anonymous';
    script.src = 'https://connect.facebook.net/vi_VN/sdk/xfbml.customerchat.js';

    script.onload = () => {
      window.FB.init({
        xfbml: true,
        version: 'v17.0',
      });
    };

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div>
      <div id="fb-root"></div>
      <div
        className="fb-customerchat"
        page_id="488231215252326"
        attribution="biz_inbox"
      ></div>
    </div>
  );
};

export default FBChatPlugin;
