// import getall from "../models/ProductModel";
import instance from "../../axiosConfig";

export async function GetAllProducts(setDataFunc) {
  // const url =process.env.REACT_APP_GET_ALL_PRODUCTS+`?pageSize=100`
  const url =process.env.REACT_APP_GET_ALL_PRODUCTS
  try {
    const response = await instance.get(url); // Sử dụng instance đã tạo
    setDataFunc(response.data.data);
  } catch (error) {
    console.error(error);
  }
}

export async function GetAllProductCategorys(setDataFunc) {
  // const url =process.env.REACT_APP_GET_ALL_PRODUCTS+`?pageSize=100`
  const url =process.env.REACT_APP_GET_ALL_PRODUCTS+"?only=category"
  // console.log(url);
  try {
    const response = await instance.get(url); // Sử dụng instance đã tạo
    setDataFunc(response.data.data);
  } catch (error) {
    console.error(error);
  }
}

export async function GetProductsById(id,setDataFunc) {

  const url =process.env.REACT_APP_GET_PRODUCT_BY_ID+`${id}`
  // console.log(url);
  try {
    const response = await instance.get(url); // Sử dụng instance đã tạo
    setDataFunc(response.data.data);
  } catch (error) {
    console.error(error);
  }
}

