import React,{ useState, useEffect, useRef } from 'react';

import GetAllTexts from '../controllers/TextSliderContoller';

//import icon
import {IoIosArrowForward} from 'react-icons/io';

//import style
import './component_styles/textSlider.scss'

const delay = 3000;
export default function TextSlider() {
  
    const [textSlider, setTextSlider] = useState()
    useEffect(() => {
        GetAllTexts(setTextSlider)
    }, []);
    
    const [index, setIndex] = useState(0);
    const timeoutRef = useRef(null);
  
    function resetTimeout() {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    }
  
    // console.log(textSlider);
  
    useEffect(() => {
      timeoutRef.current = setTimeout(
        () =>
          setIndex((prevIndex) =>
          textSlider?
            prevIndex >= textSlider?.filter(text=>text.status==="release").length - 1 ? 0 : prevIndex + 1
            :
            0
          ),
        delay
      );
  
      return () => {
        resetTimeout();
      };
    }, [index]);
  
    return (
      <div className='PoliciesAndPromotions'>
        <div className='LeftArrow'><IoIosArrowForward/></div>
        <div className="TextSlideShow">
          <div 
            className="TextSlideshowSlider"
            style={{ transform: `translate3d(${-index * 100}%, 0, 0)` }}
          >
            {textSlider?.filter(text=>text.status==="release")// show all policies which releasing
                      .sort((a,b)=>a.index-b.index)//sort by attribute index ascending
                      .map((Text, index) => (
              <div className="TextSlide" key={index} style={{ Text }}>
                {Text.text}
              </div>
            ))}
          </div>
        </div>
        <div className='RightArrow'><IoIosArrowForward/></div>
  
      </div>
      
    );
  }