import React,{ useState, useEffect,useContext } from 'react';

import { AppContext } from '../../Context/AppProvider';

import { useSelector, useDispatch } from 'react-redux'
import * as actions from '../../store/actions'
import { Link, useNavigate } from 'react-router-dom';
import '../styles/header.scss';
import { TiShoppingCart ,TiThMenu,TiThMenuOutline} from 'react-icons/ti';
import { BsFacebook,BsTiktok,BsYoutube,BsBoxSeam,BsPersonCircle,BsTelephone } from 'react-icons/bs';
import {BiLogIn,BiLogOut,BiUserPlus,BiUserPin} from 'react-icons/bi';

import ContentLoader from '../components-render/ContentLoader';
import TextSlider from '../components-render/TextSlider';
import Breadcrumb from '../components-render/BreadcrumbBar';
import FBChatPlugin from '../components-render/FBChatPlugin';

function ProductList({productList,menuToggle,setMenuToggle}){
  let groupedProducts = {};

  for (let i = 0; i < productList?.length; i++) {
      let product = productList[i];
      let category = product.category;
      
      if (groupedProducts[category]) {
          groupedProducts[category].push(product);
      } else {
          groupedProducts[category] = [product];
      }
  }
  
  // Get the keys (categories) of groupedProducts
  let categories = Object.keys(groupedProducts);

  // console.log(groupedProducts)
  const unidecode =require('unidecode') //Bỏ dấu trong tiếng Việt
  return(
    <div className="product-list">
      {categories.map((category,index)=>{
        return(
          <div className='category-box' key={`category-box-${index}`}>
            <div className='title'>
              <p>
                {category}
              </p>
            </div>
            <div className='products'>
              {
                groupedProducts[category]?.map((product,product_index)=>{
                  return(
                    <Link 
                      to={`./product?category=${unidecode(product?.category?.toLowerCase()?.replace(/\s+/g, '')||"none")?.replace(/[^a-zA-Z0-9]+/g, '')}&productId=${product?.id}`}
                      key={`products-${product_index}`} 
                      className='product-name' 
                      onClick={()=>{setMenuToggle(!menuToggle)}}
                    >
                      {product.name}
                      <hr/>
                    </Link>
                  )
                })
              }
            </div>
          </div>
        )
      })}
    </div>
  )
}

function Header() {

  const dispatch = useDispatch()
  const { isLoggedIn } = useSelector(state => state.auth)

  const [decodedToken,setDecodedToken]=useState(null);
 
  useEffect(() => { //reset decodedToken when local Storage ["persist:auth"] have changed
    function updateDecodedToken() {
      let data = JSON.parse(localStorage.getItem("persist:auth"));
      let token = data?JSON.parse(data?.token):null;

      if (token === null) {
        setDecodedToken('none');
      } else {
        // console.log(atob(token?.split(".")[1]));
        let decodedToken = JSON.parse(atob(token?.split(".")[1]));
        setDecodedToken(decodedToken);
      }
    }

    // Initial update
    updateDecodedToken();

    // Listen for storage events
    window.addEventListener('storage', (event) => {
      if (event.key === "persist:auth") {
        updateDecodedToken();
      }
    });

    // Clean up the event listener
    return () => {
      window.removeEventListener('storage', updateDecodedToken);
    };
  }, []);
  
  //Lấy sản phẩm 
  const {products,users,shopProfile, setCategoryFilterSelected,productCategories} = useContext(AppContext)

  // console.log(products);

  //Filter products
  const navigate = useNavigate();
  const [searchingValue,setSearchingValue] =useState("")
  const handleSearching =()=>{
    if(products?.filter((product)=>product.name===searchingValue).length>0){
      let product =products?.find((product)=>product.name===searchingValue);
      navigate(`/product/?category=${product.category}&productId=${product.id}`)
    }else if (products?.filter((product)=>product.category===searchingValue).length>0){
      setCategoryFilterSelected(searchingValue)
      const crollingElement = document.getElementById("product-list-show")
      crollingElement.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" })
      navigate(`./?category=${searchingValue}`)
    }else if (products?.filter((product)=>product.size===searchingValue).length>0){
      // search by size
    }else if (products?.filter((product)=>product.marterial===searchingValue).length>0){
      // search by marterial
    }else if (products?.filter((product)=>product.effect===searchingValue).length>0){      
      // search by effect
    }else{
      alert("Không tìm thấy sản phẩm!")
    }
  }

  // console.log(window.screen.width);

  
  const [menuToggle,setMenuToggle]= useState(window.screen.width<=576?false:true)

  if(window.screen.width>576){
    if(menuToggle){
      
      setMenuToggle(prev=>false)
    }
  }

  if(menuToggle){
    document.body.style.overflow="hidden";
  }else{
    document.body.style.overflow="auto";
    
  }
  const searchParams= new URLSearchParams(window.location.search)
  const product_id = searchParams.get("productId")
  useEffect(()=>{
    console.log("scroll to header");
    document.getElementById("header").scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
  },[product_id])
  // console.log(product_id);
  return (
    <>
      <header className="header" id="header">

        <div className="top-bar">
          <ul className='fanpage-list'>
            <h5>Connect with us:</h5>
              <li>
                <a href={shopProfile?.filter(item=>item.name==="facebook")[0]?.link===undefined?"":shopProfile?.filter(item=>item.name==="facebook")[0]?.link} target="_blank" rel="noopener noreferrer"> < BsFacebook className="iconu"/> </a>
              </li>
              {/* <a href="https://www.instagram.com/" target="_blank" rel="noopener noreferrer"> < BsInstagram className="iconu" /> </a> */}
              <li>
                <a href={shopProfile?.filter(item=>item.name==="tiktok")[0]?.link===undefined?"":shopProfile?.filter(item=>item.name==="tiktok")[0]?.link} target="_blank" rel="noopener noreferrer"> < BsTiktok className="iconu" /> </a>
              </li>
              <li>
                <a href={shopProfile?.filter(item=>item.name==="youtube")[0]?.link===undefined?"":shopProfile?.filter(item=>item.name==="youtube")[0]?.link} target="_blank" rel="noopener noreferrer"> < BsYoutube className="iconu" /> </a>
              </li>
          </ul>
          <ul className='right-infor'>
            {
              users?.length>0 &&
                <li key={'members-quantity'}>
                  Số thành viên: <p>{!users || users?.length<=0?"":users?.length}</p>
                </li>

            }
            {/* <li key={'to-contact'}><Link to="/contact">Liên hệ hỗ trợ <BsTelephone/> </Link></li> */}
            <li key={'to-account-infor'}>
              <div className='account-dropdown'>{isLoggedIn?`Chào ${decodedToken?.fullname?.split(' ').at(-1)}!`:'Tài khoản'} <BsPersonCircle/> </div>
              {
                ( !isLoggedIn?
                  <div className='dropdownacc-menu'>
                    <div className='dropdownacc'>

                      <Link to='/login'> <BiLogIn/> Đăng nhập</Link>
                      <Link to='/register'><BiUserPlus/> Đăng ký</Link>
                    </div>
                  </div>
                  :
                  <div className='dropdownacc-menu'>
                    <div className='dropdownacc'>

                      <Link to={`./account?userId=${decodedToken?.userId}`} > <BiUserPin/> Thông tin tài khoản</Link>
                      <Link to={`./orders?userId=${decodedToken?.userId}`}> <BsBoxSeam/> Đơn hàng của bạn</Link>
                      <Link to={`./carts?userId=${decodedToken?.userId}`} > <TiShoppingCart/> Giỏ hàng của bạn</Link>
                      <Link to='/' onClick={()=>{dispatch(actions.logout())}}> <BiLogOut/> Đăng xuất</Link>
                    </div>

                  </div>
                )
              }
            </li>
          </ul>
        </div>
      
        <div className='navbar'>
          <div className='name-n-logo'>
            <Link className="header-logo-container" to='/'>
              <div className="header__logo">
                <div className='store-logo'>
                  <img src="https://res.cloudinary.com/djchyv9o9/image/upload/v1687061903/logo_lentilab-01_yp9khv.png" alt="Company Logo" />
                </div>
                <div className='store-name'>
                  <h1>SHOP ẢNH NỔI</h1>
                  {/* <p>3D LENTICULAR</p> */}
                </div>
              </div>
            </Link>
            <div className='hamberger-Menu'>

              <button  onClick={()=>setMenuToggle(!menuToggle)}>
                  {!menuToggle?<TiThMenuOutline/>:<TiThMenu/>}
              </button>
            </div>

            <div className='searching-bar'>
              <input
                type="search"
                list='product-recomments'
                id="header-searching-input"
                name="header-searching-input"
                placeholder="Bạn cần tìm gì?"
                value={searchingValue}
                onChange={(event)=>setSearchingValue(event.target.value)}
              />

              <datalist id="product-recomments">
                {products?
                  products?.map((product,index)=>{
                    
                    return(
                      <>
                        <option key={`product-recomments-${index}`} value={product.name}></option>
                        <option key={`category-recomments-${index}`} value={product.category}></option>
                      </>
                    )
                  })
                  
                  :
                  <option value="Card bo góc"></option>
                }
              </datalist>

              <button className='searching-btn' onClick={handleSearching}>Tìm kiếm</button>
            </div>

            
          </div>
          {
            (menuToggle|| window.screen.width>576)&&
            <div className="header-nav">
              <div className="hamberger-Menu" 
                style={{
                  width:"100%",
                  height: "2em"
                }}
              >

                <button  style={{fontSize: "0.8em",fontWeight:"600"}} onClick={()=>setMenuToggle(!menuToggle)}>Đóng</button>
              </div>
              <ul>
                {/* Switching to home page */}
                  <li key={'to-home'}>
                    <Link to="/" className='switchingPage'>
                      {/* <div><TiHomeOutline className='icon'/> </div> */}
                      <div className='textHeader'>Trang chủ</div>
                    </Link>
                  </li>
                  <hr></hr>
                  {/* Switching to Cart page */}
                <li key={'to-Blog'}>
                  <Link to={isLoggedIn?`./carts?userId=${decodedToken?.userId}`:`/login`} className='switchingPage' >
                    {/* <div><TiShoppingCart className='icon'/> </div> */}
                    <div className='textHeader'>Blog</div>
                  </Link>
                </li>
                <hr></hr>
                  {/* Switching to product page*/}
                <li key={'to-product'}>
                  <div className='switchingPage'>
                    {/* <div><TiPrinter className='icon'/></div>  */}
                    <div className='textHeader'>Sản phẩm</div>
                    
                  </div>
                  <div className='dropdown-products-list'>
                    {
                      products?
                      <ProductList productList={products} menuToggle={menuToggle} setMenuToggle={setMenuToggle}/>
                      :
                      <ContentLoader/>
                    }
                  </div>
                  
                </li>
                <hr></hr>
                {/* Crolling down to Hot Product */}
                <li key={'to-hot-products'}>
                  <div className='switchingPage' onClick={
                    ()=>{
                      navigate("./")
                      document.getElementById("trending-list-content").scrollIntoView({behavior:"smooth",block:"end",inline:"nearest"})
                    }
                  }>
                    {/* <div><TiShoppingCart className='icon'/> </div> */}
                    <div className='textHeader'>Sản phẩm Hot</div>
                  </div>
                </li>
                <hr></hr>
                {/* Switching to orders page */}
                <li key={'to-order'}>
                  <Link to={isLoggedIn?`./orders?userId=${decodedToken?.userId}`:`/login`} className='switchingPage' >
                    {/* <div><BsBoxSeam className='icon'/> </div> */}
                    <div className='textHeader'> Đơn hàng</div>
                  </Link>
                </li>
                <hr></hr>
                {/* Switching to Cart page */}
                <li key={'to-cart'}>
                  <Link to={isLoggedIn?`./carts?userId=${decodedToken?.userId}`:`/login`} className='switchingPage' >
                    {/* <div><TiShoppingCart className='icon'/> </div> */}
                    <div className='textHeader'>Giỏ hàng</div>
                  </Link>
                </li>
                
                
              </ul>
            </div>
          }
        </div>
        <TextSlider/>

        {/* Breadcrumb bar */}
        <Breadcrumb/>
        <FBChatPlugin/>
      </header>

    </>
    
  );
}

export default Header;
