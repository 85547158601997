import React from "react";
import { Link } from "react-router-dom";
import "./component_styles/selectionBar.scss"

import {IoIosArrowDown} from 'react-icons/io'

function SetNewParams(name,option){
    const url = new URL(window.location.href);
    const oldParams= url.search
    let newParams=''

    newParams=`?${name}=${option}`

    // console.log("setParam is running");
    if(oldParams!==""){
        if(name==="sort"){
            const searchParams= url.searchParams.get("category")
            // console.log(oldParams);
            if(searchParams){
                newParams=`?category=${searchParams}&${name}=${option}`
            }

        }else if(name==="category"){
            const searchParams= url.searchParams.get("sort")
            if(searchParams){
                newParams=`?${name}=${option}&sort=${searchParams}`
            }
        }else{
            console.log("Invalid params name!");
        }
    }

    return newParams
}

export default function SelectionBar({options,selectedValue,setSelectedValue,name}){
    // console.log(params==="");
    return(
        <div className='selection-bar-container'>
            <h4 className='selected-value'>
                {selectedValue}
                <IoIosArrowDown/>
            </h4>
            <div className='option-list-dropdown'>
                <div className='option-list-container'>
                    {options.map((option,index)=>{
                        let newParams = SetNewParams(name, option);
                    return(
                        <Link 
                            style={{
                                backgroundColor: option === selectedValue
                                ? "#dddddd"
                                : "",
                            }}
                            to={
                                newParams
                            } 
                            key={index} 
                            onClick={()=>{setSelectedValue(option)}}>
                        {option}
                        </Link>
                    )
                    })}
                </div>
            </div>
        </div>
    )
}