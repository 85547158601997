import React, {useState, useRef} from "react"
import {BsFillPlayCircleFill} from 'react-icons/bs'
import {TbSquareRoundedChevronLeftFilled,TbSquareRoundedChevronRightFilled} from 'react-icons/tb'
import {IoCloseSharp,IoEyeSharp} from 'react-icons/io5'
import {AiFillStar} from 'react-icons/ai'
import 'reactjs-popup'
import Popup from "reactjs-popup"

import '../components-render/component_styles/popup.scss'

const contact_infor=[
    {
        phone:"093 761 7695",
    },
    {
        phone:"096 533 7436",
    }
]

const cmt_data=[
    {
      avt_src:'https://cdn.glitch.global/2433c7c8-62e4-4466-8902-cf217007c15f/MicrosoftTeams-image.png?v=1688537490084',
      name:'Nguyễn Tuấn Vinh',
      time:'12:10 am',
      date:'06/05/2002',
      rating:3,
      content:'something',
    },
    {
      avt_src:'https://cdn.glitch.global/2433c7c8-62e4-4466-8902-cf217007c15f/MicrosoftTeams-image.png?v=1688537490084',
      name:'Nguyễn Tuấn Vinh',
      time:'12:10 am',
      date:'06/05/2002',
      rating:4,
      content:'something 1',
    },
    {
      avt_src:'https://cdn.glitch.global/2433c7c8-62e4-4466-8902-cf217007c15f/MicrosoftTeams-image.png?v=1688537490084',
      name:'Nguyễn Tuấn Vinh',
      time:'12:10 am',
      date:'06/05/2002',
      rating: 5,
      content:'something 2',
    },
    {
      avt_src:'https://cdn.glitch.global/2433c7c8-62e4-4466-8902-cf217007c15f/MicrosoftTeams-image.png?v=1688537490084',
      name:'Nguyễn Tuấn Vinh',
      time:'12:10 am',
      date:'06/05/2002',
      rating:3,
      content:'something',
    },
    {
      avt_src:'https://cdn.glitch.global/2433c7c8-62e4-4466-8902-cf217007c15f/MicrosoftTeams-image.png?v=1688537490084',
      name:'Nguyễn Tuấn Vinh',
      time:'12:10 am',
      date:'06/05/2002',
      rating:4,
      content:'something 1',
    },
    {
      avt_src:'https://cdn.glitch.global/2433c7c8-62e4-4466-8902-cf217007c15f/MicrosoftTeams-image.png?v=1688537490084',
      name:'Nguyễn Tuấn Vinh',
      time:'12:10 am',
      date:'06/05/2002',
      rating: 5,
      content:'something 2',
    },
    {
      avt_src:'https://cdn.glitch.global/2433c7c8-62e4-4466-8902-cf217007c15f/MicrosoftTeams-image.png?v=1688537490084',
      name:'Nguyễn Tuấn Vinh',
      time:'12:10 am',
      date:'06/05/2002',
      rating:3,
      content:'something',
    },
    {
      avt_src:'https://cdn.glitch.global/2433c7c8-62e4-4466-8902-cf217007c15f/MicrosoftTeams-image.png?v=1688537490084',
      name:'Nguyễn Tuấn Vinh',
      time:'12:10 am',
      date:'06/05/2002',
      rating:4,
      content:'something 1',
    },
    {
      avt_src:'https://cdn.glitch.global/2433c7c8-62e4-4466-8902-cf217007c15f/MicrosoftTeams-image.png?v=1688537490084',
      name:'Nguyễn Tuấn Vinh',
      time:'12:10 am',
      date:'06/05/2002',
      rating: 5,
      content:'something 2',
    },
    {
      avt_src:'https://cdn.glitch.global/2433c7c8-62e4-4466-8902-cf217007c15f/MicrosoftTeams-image.png?v=1688537490084',
      name:'Nguyễn Tuấn Vinh',
      time:'12:10 am',
      date:'06/05/2002',
      rating:3,
      content:'something',
    },
    {
      avt_src:'https://cdn.glitch.global/2433c7c8-62e4-4466-8902-cf217007c15f/MicrosoftTeams-image.png?v=1688537490084',
      name:'Nguyễn Tuấn Vinh',
      time:'12:10 am',
      date:'06/05/2002',
      rating:4,
      content:'something 1',
    },
    {
      avt_src:'https://cdn.glitch.global/2433c7c8-62e4-4466-8902-cf217007c15f/MicrosoftTeams-image.png?v=1688537490084',
      name:'Nguyễn Tuấn Vinh',
      time:'12:10 am',
      date:'06/05/2002',
      rating: 5,
      content:'something 2',
    },
    {
      avt_src:'https://cdn.glitch.global/2433c7c8-62e4-4466-8902-cf217007c15f/MicrosoftTeams-image.png?v=1688537490084',
      name:'Nguyễn Tuấn Vinh',
      time:'12:10 am',
      date:'06/05/2002',
      rating:3,
      content:'something',
    },
    {
      avt_src:'https://cdn.glitch.global/2433c7c8-62e4-4466-8902-cf217007c15f/MicrosoftTeams-image.png?v=1688537490084',
      name:'Nguyễn Tuấn Vinh',
      time:'12:10 am',
      date:'06/05/2002',
      rating:4,
      content:'something 1',
    },
    {
      avt_src:'https://cdn.glitch.global/2433c7c8-62e4-4466-8902-cf217007c15f/MicrosoftTeams-image.png?v=1688537490084',
      name:'Nguyễn Tuấn Vinh',
      time:'12:10 am',
      date:'06/05/2002',
      rating: 5,
      content:'something 2',
    },
    {
      avt_src:'https://cdn.glitch.global/2433c7c8-62e4-4466-8902-cf217007c15f/MicrosoftTeams-image.png?v=1688537490084',
      name:'Nguyễn Tuấn Vinh',
      time:'12:10 am',
      date:'06/05/2002',
      rating:3,
      content:'something',
    },
    {
      avt_src:'https://cdn.glitch.global/2433c7c8-62e4-4466-8902-cf217007c15f/MicrosoftTeams-image.png?v=1688537490084',
      name:'Nguyễn Tuấn Vinh',
      time:'12:10 am',
      date:'06/05/2002',
      rating:4,
      content:'something 1',
    },
    {
      avt_src:'https://cdn.glitch.global/2433c7c8-62e4-4466-8902-cf217007c15f/MicrosoftTeams-image.png?v=1688537490084',
      name:'Nguyễn Tuấn Vinh',
      time:'12:10 am',
      date:'06/05/2002',
      rating: 5,
      content:'something 2',
    },
    {
      avt_src:'https://cdn.glitch.global/2433c7c8-62e4-4466-8902-cf217007c15f/MicrosoftTeams-image.png?v=1688537490084',
      name:'Nguyễn Tuấn Vinh',
      time:'12:10 am',
      date:'06/05/2002',
      rating:3,
      content:'something',
    },
    {
      avt_src:'https://cdn.glitch.global/2433c7c8-62e4-4466-8902-cf217007c15f/MicrosoftTeams-image.png?v=1688537490084',
      name:'Nguyễn Tuấn Vinh',
      time:'12:10 am',
      date:'06/05/2002',
      rating:4,
      content:'something 1',
    },
    {
      avt_src:'https://cdn.glitch.global/2433c7c8-62e4-4466-8902-cf217007c15f/MicrosoftTeams-image.png?v=1688537490084',
      name:'Nguyễn Tuấn Vinh',
      time:'12:10 am',
      date:'06/05/2002',
      rating: 5,
      content:'something 2',
    },
    {
      avt_src:'https://cdn.glitch.global/2433c7c8-62e4-4466-8902-cf217007c15f/MicrosoftTeams-image.png?v=1688537490084',
      name:'Nguyễn Tuấn Vinh',
      time:'12:10 am',
      date:'06/05/2002',
      rating:3,
      content:'something',
    },
    {
      avt_src:'https://cdn.glitch.global/2433c7c8-62e4-4466-8902-cf217007c15f/MicrosoftTeams-image.png?v=1688537490084',
      name:'Nguyễn Tuấn Vinh',
      time:'12:10 am',
      date:'06/05/2002',
      rating:4,
      content:'something 1',
    },
    {
      avt_src:'https://cdn.glitch.global/2433c7c8-62e4-4466-8902-cf217007c15f/MicrosoftTeams-image.png?v=1688537490084',
      name:'Nguyễn Tuấn Vinh',
      time:'12:10 am',
      date:'06/05/2002',
      rating: 5,
      content:'something 2',
    },
    {
      avt_src:'https://cdn.glitch.global/2433c7c8-62e4-4466-8902-cf217007c15f/MicrosoftTeams-image.png?v=1688537490084',
      name:'Nguyễn Tuấn Vinh',
      time:'12:10 am',
      date:'06/05/2002',
      rating:3,
      content:'something',
    },
    {
      avt_src:'https://cdn.glitch.global/2433c7c8-62e4-4466-8902-cf217007c15f/MicrosoftTeams-image.png?v=1688537490084',
      name:'Nguyễn Tuấn Vinh',
      time:'12:10 am',
      date:'06/05/2002',
      rating:4,
      content:'something 1',
    },
    {
      avt_src:'https://cdn.glitch.global/2433c7c8-62e4-4466-8902-cf217007c15f/MicrosoftTeams-image.png?v=1688537490084',
      name:'Nguyễn Tuấn Vinh',
      time:'12:10 am',
      date:'06/05/2002',
      rating: 5,
      content:'something 2',
    },
    {
      avt_src:'https://cdn.glitch.global/2433c7c8-62e4-4466-8902-cf217007c15f/MicrosoftTeams-image.png?v=1688537490084',
      name:'Nguyễn Tuấn Vinh',
      time:'12:10 am',
      date:'06/05/2002',
      rating:3,
      content:'something',
    },
    {
      avt_src:'https://cdn.glitch.global/2433c7c8-62e4-4466-8902-cf217007c15f/MicrosoftTeams-image.png?v=1688537490084',
      name:'Nguyễn Tuấn Vinh',
      time:'12:10 am',
      date:'06/05/2002',
      rating:4,
      content:'something 1',
    },
    {
      avt_src:'https://cdn.glitch.global/2433c7c8-62e4-4466-8902-cf217007c15f/MicrosoftTeams-image.png?v=1688537490084',
      name:'Nguyễn Tuấn Vinh',
      time:'12:10 am',
      date:'06/05/2002',
      rating: 5,
      content:'something 2',
    },
  ]

function calculateAverage(objectArray, attribute) {
let sum = 0;
for (const object of objectArray) {
    sum += object[attribute];
}

const average = sum / objectArray.length;

return average;
}

export default function ProductPreview ({product_preview,productSeen}){

    const thumbBarRef = useRef(null); // make the thumb-item-active into the overflow range

    //from line 10 to 50: Make crolling thumbnail bar
    const [showIndex,SetShowIndex]= useState(0)

    const handleSetShowindex=(index)=>{
        // console.log(showIndex);
        SetShowIndex(index)
    }
    const handleIncShowindex=()=>{
        let temp=showIndex+1
        
        handleSetShowindex(temp)
        if(temp>product_preview?.length-1){
            SetShowIndex(0)
            temp=0;
        }  

        const thumbItems = thumbBarRef?.current.getElementsByClassName("thumb");
        if (thumbItems[temp]) {
            // console.log(thumbItems[temp]);
          thumbItems[temp].scrollIntoView({
            behavior: 'smooth',
            block: 'nearest',
            inline: 'center',
          });
        }
    }

    const handleDecShowindex=()=>{
        let temp=showIndex-1
        SetShowIndex(temp)
        if(temp<0){
            SetShowIndex(product_preview?.length-1)
            temp=product_preview?.length-1
        }  
        // console.log('temp:',temp);
        const thumbItems = thumbBarRef.current.getElementsByClassName('thumb');
        if (thumbItems[thumbItems.length-1]) {
            // console.log(thumbItems[thumbItems.length-1]);

          thumbItems[thumbItems.length-1].scrollIntoView({
            behavior: 'smooth',
            block: 'nearest',
            inline: 'center',
          });
        }
    }
    // console.log(product_preview)
    function extractVideoCode(url) {
        const match = url?.match(/(?:[?&]v=|\/embed\/|\/v\/|\.be\/)([\w-]{11})/);
        if (match) {
          return match[1];
        }
        return null; // Return null if no match is found
      }
    //   console.log(product_preview);
    const renderProductPreview=()=>{
        return(

            <Popup  className="fullscreen-preview" trigger=
                {
                    showIndex===0?
                        <div className="product-preview-container">
                            <iframe className='show' src={`https://www.youtube.com/embed/${extractVideoCode(product_preview?.[0])}?autoplay=1&mute=1`}>
                            </iframe>    
                            {/* <iframe

                                className='video-show' src={`https://www.youtube.com/embed/1Xfdjqa5dfY?autoplay=1&mute=1`} title="video">
                            </iframe> */}
        
                        </div>
                        :
                        <div className="product-preview-container">
                            
                            <img className='show' src={product_preview?.[showIndex].imgSrc} alt="product-img"/>
                        </div>


                }
                modal nested>
                {
                    close => (
                        <div className='fullscreen-preview-modal'>
                            <div className="preview-btns">
                                <button className="fullscreen-off-btn" onClick=
                                    {() => close()}>
                                        <IoCloseSharp/>
                                </button>
                            </div>
                            <div className='content'>
                                <div className="product-show-fullscreen">
                                    {
                                        showIndex===0?
                                        <div className="product-preview-container">
                                            <iframe className='show' src={`https://www.youtube.com/embed/${extractVideoCode( product_preview?.[0])}?autoplay=1&mute=1`}>
                                            </iframe>    
                                            {/* <iframe
                                                className='video-show' src={`https://www.youtube.com/embed/1Xfdjqa5dfY?autoplay=1&mute=1`} title="video">
                                            </iframe> */}
                        
                                        </div>
                                        :
                                        <div className="product-preview-container">
                                            
                                            <img className='show' src={product_preview?.[showIndex].imgSrc} alt="product-img"/>
                                        </div>
                                    }
                                </div>
                                <div className='product-thumb-bar-fullscreen'>
                                    <button className="switch-btn" onClick={handleDecShowindex}>
                                        <TbSquareRoundedChevronLeftFilled/>
                                    </button>
                                    <div className="product-thumb-bar-content" >

                                        <div className='product-thumb-container' ref={thumbBarRef}>

                                            {product_preview?.map((thumb_src,index)=>{
                                                // console.log(thumb_src);
                                                return(
                                                    <div key={`product-thumb-${index}`} className={showIndex===index?'thumb-item-active':'thumb-item-fullscreen'} onClick={()=>handleSetShowindex(index)}>
                                                        <img className='thumb' src={thumb_src.imgSrc} alt='thumbail-item' />
                                                        {
                                                            index===0&&
                                                                <div className="play-video">
                                                                <BsFillPlayCircleFill/>
                                                                </div>
                                                        }
                                                    
                                                    </div>  
                                                    
                                                )
                                            })}
                                        </div>
                                    </div>
                                    <button className="switch-btn" onClick={handleIncShowindex}>
                                        <TbSquareRoundedChevronRightFilled/>
                                    </button>

                                    </div>  
                                </div>
                        </div>
                    )
                }
            </Popup>    
        )

    }

    return(
        <div className='product-images'>

          <div className='product-show'>

            {renderProductPreview()}
            <div className="preview-point">
                <span className="point seen-point">
                    {productSeen} <IoEyeSharp/> 
                </span>
                <span className="point rated-point">
                    <b>{calculateAverage(cmt_data,"rating")}</b>/5 <AiFillStar/>
                </span>
            </div>
          </div>
        <div className="contact-infor">

            <div className="contact-instuction">
                <span className="instuction">
                    Bạn có thể đặt hàng trực tiếp hoặc biết thêm thông tin sản phẩm thông qua Zalo: <b>{contact_infor[0].phone}</b> hoặc <b>{contact_infor[1].phone}</b>
                </span>  

            </div>


        </div>
        <div className='product-thumb-bar'>
        <button className="switch-btn" onClick={handleDecShowindex}>
            <TbSquareRoundedChevronLeftFilled/>
        </button>
        <div className="product-thumb-bar-content" >

            <div className='product-thumb-container' ref={thumbBarRef}>

                {product_preview?.map((thumb_src,index)=>{
                    return(
                        <div key={`product-thumb-${index}`} className={showIndex===index?'thumb-item-active':'thumb-item'} onClick={()=>handleSetShowindex(index)}>
                            <img className='thumb' src={thumb_src.imgSrc} alt='thumbail-item' />
                            {
                                index===0&&
                                    <div className="play-video">
                                    <BsFillPlayCircleFill/>
                                    </div>
                            }
                        
                        </div>  
                        
                    )
                })}
            </div>
        </div>
        <button className="switch-btn" onClick={handleIncShowindex}>
            <TbSquareRoundedChevronRightFilled/>
        </button>

        </div>   

      </div>
    )
}