import instance from "../../axiosConfig";
import { GetToken } from "./TokenController";

export async function GetAllCart(setDataFunc) {
  const url = process.env.REACT_APP_GET_ALL_CARTS
  // console.log(url);
    try {
      const response = await instance.get(url)
      setDataFunc(response.data.data)

    } catch (error) {
      // Handle any errors that may occur during the promise execution
      console.error(error);
    }
}

export async function AddNewCart(data,userId){
  console.log(data);
  const url =process.env.REACT_APP_ADD_NEW_CART
  try{
      // console.log(url);
     const response =await instance.post(url,data)
     console.log(response);
  } catch (error) {
    // Handle network or other errors
    console.error('Error:', error);
  }
}

export async function DelteCartById(cartId){

    try{
        const url =process.env.REACT_APP_DELETE_CART_BY_USER_ID_API+`=${cartId}`
        // console.log(url);
        const token= GetToken("persist:auth")
        // console.log(token);
        const headers = {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json', // Set the content type based on your request
        };
        const response = await fetch(url, {
            method: 'DELETE',
            headers: headers,
        })
        .then(response => {
            if (response.ok) {
            // Item deleted successfully
            // Perform any necessary actions after successful deletion
            console.log('Item deleted successfully');
            } else {
            // Item deletion failed
            // Handle the error or display an error message
            console.error('Failed to delete item');
            }
        })
        .catch(error => {
            // An error occurred during the request
            // Handle the error or display an error message
            console.error('Error occurred while deleting item', error);
        });

        if (response.ok) {
        // Request was successful
        // const data = await response.json();
        // console.log(data);
        return 1
      } else {
        // Handle the error condition
        console.log('Error:', response.status);
      }
    } catch (error) {
      // Handle network or other errors
      console.error('Error:', error);
    }
}

