import React, { useState,useEffect,useContext } from "react";
import OrderedProductDetail from "../components-render/OrderedProductDetail";


import '../styles/order.scss'
import DescriptionRender from "../components-render/DescriptionReder";
import { GetAllOrder } from "../controllers/OrderController";
import RelationProducts from "../components-render/RelationProduct"
import { AppContext } from "../../Context/AppProvider";

const payment_method=[
    {   
        title:"Thanh toán qua MoMo",
        content:"Tài khoản: 096 533 7436. /br/Lời nhắn: <Mã đơn hàng>",
        img:"",
    },
    {   
        title:"Chuyển khoản qua ngân hàng",
        content:"Tài khoản: 096 533 7436. /br/Lời nhắn: <Mã đơn hàng>",
        img:"",
    },
]

function Order(){

    const {products} = useContext(AppContext)

    const [orders, setOrders]= useState([])
    const currentURL = window.location.href;
    const user_id = currentURL.match(/userId=(\d+)/)[1];
    useEffect(() => {
        GetAllOrder(setOrders)
    }, []);

    console.log(orders);

    return(
        <div className="main">
            <div className="order-wrapper">
                
                <div className="order-container">
                    <div className="order-title">
                        <h2>Đơn hàng của bạn</h2>
                    </div>
                    <div className="order-list">

                        {
                            orders?.length<=0||!orders?"Bạn chưa có đơn hàng nào hết!":
                            orders?.map(order=>{
                                return(
                                    <>
                                    
                                        <div className="order-overview">

                                            <div className="order-code infor">
                                                <p className="key">Mã đơn hàng:</p>
                                                <p className="value">{order.orderCode}</p>
                                            </div>
                                            <div className="order-date infor">
                                                <p className="key">Ngày đặt hàng:</p>
                                                <p className="value">{order.createdAt}</p>
                                            </div>
                                            {/* <div className="order-cost infor">
                                                <p className="key">Tổng tiền:</p>
                                                <p className="value">{order.orderTotal}</p>
                                            </div> */}
                                            <div className="order-status infor">
                                                <p className="key">Tình trạng:</p>
                                                <p className="value">{order.status}</p>
                                            </div>
                                            <div className="order-status infor">
                                                <p className="key">Nguời nhận:</p>
                                                <p className="value">{order.nameRecieve}</p>
                                            </div>
                                            <div className="order-status infor">
                                                <p className="key">Địa chỉ:</p>
                                                <p className="value">{order.address}</p>
                                            </div>
                                            <div className="order-status infor">
                                                <p className="key">Số điện thoại:</p>
                                                <p className="value">{order.phone}</p>
                                            </div>
                                        </div>

                                        <div className="order-item-list">
                                            <div className="title">
                                                <p>
                                                    Đơn hàng bao gồm:
                                                </p>
                                            </div>
                                            {
                                                order?.productList?.map((item,index)=>{
                                                    return(
                                                        <OrderedProductDetail product_infor={item}/>
                                                    )
                                                })
                                            }
                                            
                                        </div>
                                    </>
                                )
                            })
                        }
                    </div>


                    <div className="payment-instructor">
                        <div className="title">
                            <p>
                                Hướng dẫn thanh toán:
                            </p>
                        </div>
                        <div className="payment-instructor-container">

                            {payment_method.map(item=>{
                                return(
                                    <DescriptionRender description_data={item} numberic={false}/>
                                )
                            })}
                        </div>
                        <div className="note">
                            <p>
                                <i><b>Lưu ý:</b></i> 
                            </p>
                            <p>
                                - Vui lòng kiểm tra thông tin của bạn trước khi thanh toán.
                            </p>
                            <p>
                                - Sau khi thanh toán thành công, bạn vui lòng chụp lại màn hình và gửi qua Zalo: 
                            </p>
                            <p>
                                -- Minh Hiên - 0937617695
                            </p>
                        </div>
                    </div>
                </div>

                <RelationProducts relationProducts={products} currentProduct={"none"}/>
            </div>
            
        </div>
    )

}export default Order