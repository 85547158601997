import React,{useContext} from 'react';
import "./component_styles/priceTable.scss";

import { AppContext } from '../../Context/AppProvider';

function PriceTable ({priceTable}) {
    // console.log(priceTable);

    let phones=[]
    const {shopProfile}= useContext(AppContext);
    if (!shopProfile || shopProfile.length<=0) {
        // Display a loading indicator or handle the absence of data
        // console.log(shopProfile);
        return <div>Loading...</div>;
    }else{
        phones=shopProfile.filter(contact=>contact.title==="Hotline");
    }    
    // console.log(phones);

  return(
    <div className='price-table'>

        {
            priceTable.value==="Liên hệ"||priceTable.value===""||priceTable.value===null?
            <div className='size-dont-contain-price-table'>
                <span> Để biết thêm bảng giá cho kích thước <b>{priceTable.size} cm</b>, xin liên hệ qua Zalo/ số điện thoại: 
                
                    {phones.map(phone=>{
                        return(
                            <p>
                                <b style={{color:"red"}}>{phone.link} </b>
                            </p>
                        )
                    })}
                </span>
            </div>
            :
            <table className="price-tables">
                <thead>
                    <tr>
                        <th scope="col" colSpan={1}>

                        Kích thước: {priceTable.size} {priceTable.size!=="Áp dụng mọi kích thước"?"cm":""}                     

                        </th>
                        <th scope="col" colSpan={4}>
                            Chất liệu : {priceTable.material}  
                        </th>
                    </tr>
                    <tr>
                        <th scope="col" rowSpan={2}>
                            Số lượng   
                        </th>
                        <th scope="col" colSpan={2}>
                            <p>In 1 mặt</p>     
                        </th>
                        <th scope="col" colSpan={2}>
                            <p>In 2 mặt</p>   
                        </th>
                        
                    </tr>
                    <tr>
                        <th scope="col" colSpan={1}>
                            <p>Không giới hạn mẫu ( VNĐ/cái)</p>     
                        </th>
                        <th scope="col" colSpan={1}>
                            <p>
                                Giới hạn {priceTable.lim1} mẫu ( VNĐ/cái)
                            </p>
                        </th>
                        <th scope="col" colSpan={1}>
                            <p>Không giới hạn mẫu ( VNĐ/cái)</p>     
                        </th>
                        <th scope="col" colSpan={1}>
                            <p>
                                Giới hạn {priceTable.lim2} mẫu ( VNĐ/cái)
                            </p>      
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {priceTable?.value?.split(';').map((row,index)=>{
                        return (
                            <tr key={index}>
                                {row?.split(',').map((cell,index)=>{
                                    return(
                                        <td>

                                            {index!==0?(cell===""?"Không có":`${cell}k`):cell}

                                        </td>
                                    )
                                })}

                            </tr>
                        )
                    })}
                    
                </tbody>
            </table>
        }
    
    </div>            

  )
};

export default PriceTable;
