import React,{useContext} from "react";
import { Link, useLocation } from "react-router-dom";

//import style
import './component_styles/breadcrumbBar.scss'

//import icons
import {IoIosArrowForward} from 'react-icons/io';

//import components
import { AppContext } from "../../Context/AppProvider";

const pageNameMapping={
    product: "Các sản phẩm",
    account: "Tài khoản của bạn",
    login:"Đăng nhập",
    register:"Đăng ký",
    orders:"Các đơn hàng hiện có",
    orderConfirmation:"Xác nhận đơn hàng",
    accountVerification:"Xác định tài khoản",
    carts:"Giỏ hàng của bạn",
    
}

export default function Breadcrumb(){
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const currentProduct = searchParams.get("productId")
    const page_breadcrumbs= location.pathname.split('?')[0].split('/').filter((substring) => substring !== '');
    
    //Lấy sản phẩm 
    const {products} = useContext(AppContext)
      // Check if products are available
    if (!products || !products.length) {
        // Display a loading indicator or handle the absence of data
        return <div></div>;
    }

    // Continue with your component logic using the products data
    // console.log(products);

    return(
        <div className='containerpb'>
            { page_breadcrumbs[0] !=="home" && page_breadcrumbs.length !==0 &&
            <div className='page-breadcrumb'>
                <div className='website-name'>
                3D Lenticular
                </div>
                {page_breadcrumbs.map((Text, index) => (
                <div className="page-name-breadcrumbs" key={index}>
                    <IoIosArrowForward className='right-arrow-breadcrumb'/>
                    {
                    Text==="product"?
                    <>
                        <Link to="./" className='page-name'>
                            Các sản phẩm
                        </Link>

                        <IoIosArrowForward className='right-arrow-breadcrumb'/>

                        <Link to={`./?category=${products?.filter(product=>product.id===+currentProduct)[0].category}`} className='page-name'>
                            {products?.filter(product=>product.id===+currentProduct)[0].category}
                        </Link>

                        <IoIosArrowForward className='right-arrow-breadcrumb'/>

                        <div className='page-name'>
                            {products?.filter(product=>product.id===+currentProduct)[0].name}
                        </div>
                    </>
                    :
                    <div className='page-name'>
                        {pageNameMapping[Text]}
                    </div>
                    }
                </div>
                ))}
            </div>
            }
        </div>
    )
}