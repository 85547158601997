import React, {useState} from "react";
import './component_styles/renderUserProfile.scss'

import * as process from '../models/env'

export default function RenderUserProfile({userInfor, setUserState}){

    //Toggle editing mode
    
    const [fullName, setFullName] = useState(false)
    const [email, setEmail] = useState(false)
    const [phone, setPhone] = useState(false)

    const handleUpdateAccInfor = async () => {
        const url = process.REACT_SERVER_SRC+`/api/v1/auth/changeinfo`;
        
        try {
          const response = await fetch(url, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email: userInfor.email,
                fullname: userInfor.fullname,
                phone: userInfor.phone,
            }),
          });
          
          if (response.ok) {
            console.log('Account updated successfully!');
          } else {
            console.log('Error updating account.');
          }
          window.location.reload();
        } catch (error) {
          console.log('An error occurred:', error);
        }
      };


    //Change avatar
    const [avatarImg, setAvatar] =useState("https://via.placeholder.com/150x150")
    const handleImageChange = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onload = (event) => {
            const img = new Image();
            img.src = event.target.result;
        
            img.onload = () => {
                if (file.size > 500000) { // limit to 500KB
                alert("File size is too large!");
                } else {
                setAvatar(event.target.result);
                }
            };
            };
        
            reader.readAsDataURL(file);
    };    
    // console.log(userInfor);
    return(
        <div className="my-profile">
        <div className="title">
            <p>Thông tin của tôi</p>
        </div>
        <div className="avt">
            <div className="show-avatar-box">

                <img className="show-avatar" src={userInfor?.avt} alt='avatar'/>
            </div>
            <div className="upload-new-avt">
                <div className="upload-btn">
                    <label className="select-new-avt" htmlFor="avatar-uploads">Chọn ảnh mới</label>
                    <p className="img-size-limit">Ảnh chỉ được tối đa 500KB</p>
                
                </div>
                <input
                    type="file"
                    id="avatar-uploads"
                    name="avatar-uploads"
                    accept=".jpg, .jpeg, .png"
                    onChange={handleImageChange}
                />
            </div>
        
        </div>
        <div className="detail-infor">

            <div className="line">
                <p className="name">Họ tên:</p>
                {fullName ?
                    <span className="infoandbutton">
                        <input className="data" defaultValue={userInfor?.fullname} onChange={(e) => { setUserState({ ...userInfor, fullname: e.target.value }) }} />
                        <button onClick={() => { setFullName(!fullName); }}>Lưu</button>
                    </span>
                    :
                    <span className="infoandbutton">
                        {/* <p className="data">{userInfor.name}</p> */}
                        <p className="data">{userInfor?.fullname}</p>
                        <button onClick={() => { setFullName(!fullName) }}>Thay đổi</button>
                    </span>
                }
            </div>

            <div className="line">
                <p className="name">Email:</p>
                {email ?
                    <span className="infoandbutton">
                        <input className="data" defaultValue={userInfor?.email} onChange={(e) => { setUserState({ ...userInfor, email: e.target.value }) }} />
                        <button onClick={() => { setEmail(!email) }}>Lưu</button>
                    </span>
                    :
                    <span className="infoandbutton">
                        <p className="data">{userInfor?.email}</p>
                        {/* <button onClick={() => { setEmail(!email) }}>Thay đổi</button> */}
                    </span>
                }
            </div>
            <div className="line">
                <p className="name">Sđt:</p>
                {phone ?
                    <span className="infoandbutton">
                        <input className="data" defaultValue={userInfor?.phone} onChange={(e) => { setUserState({ ...userInfor, phone: e.target.value }) }} />
                        <button onClick={() => { setPhone(!phone) }}>Lưu</button>
                    </span>
                    :
                    <span className="infoandbutton">
                        <p className="data">{userInfor?.phone}</p>
                        <button onClick={() => { setPhone(!phone) }}>Thay đổi</button>
                    </span>
                }
            </div>
                
            <div className="submit-user">
                <button onClick={handleUpdateAccInfor}>Cập nhật</button>
            </div>



        </div>
    </div>
    )
}