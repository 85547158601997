import React,{lazy,Suspense,useState, useEffect,useContext} from 'react';
import '../styles/home.scss'

//import icon
import { MdDesignServices } from 'react-icons/md'

//import components
import ImageSlider from '../components-render/ImageSlider';
// import FBChatPlugin from '../components-render/FBChatPlugin';
import HotProducts from '../components-render/HotProducts';
import ContentLoader from '../components-render/ContentLoader';
import SelectionBar from '../components-render/SelectionBar';
import { AppContext } from '../../Context/AppProvider';
// import ProductList from '../components-render/ProductList';

const ProductList = lazy(()=>import('../components-render/ProductList'))

const SORT_METHOD_DEFINE=[
  "Bình thường",
  "Lượt xem tăng dần",
  "Lượt xem giảm dần",
  "Xếp theo giá tăng dần",
  "Xếp theo giá giảm dần",
]

function Home() {

  const {productCategories,categoryFilterSelected, setCategoryFilterSelected} = useContext(AppContext);
  
  // console.log(productCategories);
  const [sortMethodSelected,setSortMethodSelected]=useState("")
  
  // Kiểm tra thanh url đã có "?category={Lựa chọn lọc}" hay chưa.
  // Nếu có, nó sẽ thiết lập giá trị ban đầu là giá trị category trên  url.
  // Nếu không thì giá trị ban đầu của nó là giá trị đầu tiên lấy được từ dữ liệu trên.
  useEffect(() => {
    const url = new URL(window.location.href);
    const categoryParam = url.searchParams.get("category");
    const sortParam = url.searchParams.get("sort");
    if (categoryParam) {
      setCategoryFilterSelected(categoryParam);
    } else {

        setCategoryFilterSelected("Tất cả");
    }
    if (sortParam) {
      setSortMethodSelected(sortParam);
    } else {

      setSortMethodSelected("Bình thường");
    }
  }, []);

  // Lấy các lựa chọn lọc từ dữ liệu
  const uniqueCategories = ["Tất cả",...new Set(productCategories?.map((item) => item.category))];
  
  // console.log(categoryFilterSelected);
  return (
    <>
      <ImageSlider/>
      <div className="main-content">
        <HotProducts/>

        <div className='product-list-container'>
          <div className='product-list-title'>
            <h1 className='start-your-design'>
              BẮT ĐẦU THIẾT KẾ CỦA BẠN NÀO
              <MdDesignServices className='design-icon' />
            </h1>
    
            <div className='please-chose-product-type'>
              Hãy chọn loại sản phẩm bạn muốn in ấn: 
            </div>

            <div className='product-filter'>
              <div className='category-filter filter'>
                <h4 className='filter-title'>Loại:</h4>
                <div className='selection-filter'>

                  <SelectionBar options={uniqueCategories} selectedValue={categoryFilterSelected} setSelectedValue={setCategoryFilterSelected} name={"category"}/>
                </div>
              </div>

              <div className='sort-filter filter'>
                <h4 className='filter-title'>Sắp xếp:</h4>
                <div className='selection-filter'>

                  <SelectionBar options={SORT_METHOD_DEFINE} selectedValue={sortMethodSelected} setSelectedValue={setSortMethodSelected} name={"sort"}/>
                </div>
              </div>
              {/* <div className='material-filter'>

              </div>

              <div className='effect-filter'>
                
              </div> */}

              {/* <div className='product-sort'>
                
              </div>

              <div className='search-bar'>

              </div> */}
            </div>
          </div>
          <div className='product-list-show' id="product-list-show">

            <Suspense fallback={<ContentLoader/>}>

              <ProductList selectedCategoryFilter={categoryFilterSelected} sortMethodSelected={sortMethodSelected} />
            </Suspense>
          </div>

        </div>
      </div>
    </>
  );
}

export default Home;
