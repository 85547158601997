import React,{useState, useEffect} from 'react'; 

import './component_styles/commentsArea.scss'

import PersonalComment from './PersonalComment'

import {AiFillStar} from 'react-icons/ai'
import { useContext } from 'react';
import { AppContext } from '../../Context/AppProvider';
import { GetAllComments, PostComment } from '../controllers/CommentsController';
 /**
  * This code allow user comment in thier product which the purchased and display other's comments
  * 
  *It contains two sub-div with className are: 
  ** write-comment-area: getting comment from users
  ** diplay-comment-area: diplaying other's comment
  */ 

const DEFAULT_LIMIT_NUMBER_OF_COMMENTS=10;

function CommentsArea ({productId}) {

  const [decodedToken,setDecodedToken]=useState(null);
 
  const [ currentData,setCurrent] = useState(decodedToken)

  let currentUser={}
  const {users} = useContext(AppContext)

  if(users || !decodedToken){
    currentUser=users?.find((user)=>user.id===decodedToken?.userId)
  }

  // console.log(users);

  useEffect(() => {
    // This effect runs when decodedToken changes
    // console.log('Decoded Token:', decodedToken);

    if (decodedToken) {
      // console.log('fetching data with userId:', decodedToken.userId);
      // currentUser=users?.find((user)=>user.id===decodedToken?.userId)
    }
  }, [decodedToken]);

  useEffect(() => { //reset decodedToken when local Storage ["persist:auth"] have changed
    function updateDecodedToken() {
      let data = JSON.parse(localStorage.getItem("persist:auth"));
      let token = JSON.parse(data?.token);

      if (token === null) {
        setDecodedToken('none');
      } else {
        let decodedToken = JSON.parse(atob(token?.split(".")[1]));
        setDecodedToken(decodedToken);
      }
    }

    // Initial update
    updateDecodedToken();

    // Listen for storage events
    window.addEventListener('storage', (event) => {
      if (event.key === "persist:auth") {
        updateDecodedToken();
      }
    });

    // Clean up the event listener
    return () => {
      window.removeEventListener('storage', updateDecodedToken);
    };
  }, []);

  // console.log(productId);
  
  const [commentInp,setComInp]=useState('')
  const [rating, setRating] = useState(0);  
  const [commentList, setCommentList]=useState([])

  useEffect(()=>{
    GetAllComments(setCommentList)
  },[rating])

  // console.log(commentList);
  const handlePostComment = (text) => {
    
    let currentdate = new Date();
    // var cmt={
    //   avt_src:'https://cdn.glitch.global/2433c7c8-62e4-4466-8902-cf217007c15f/MicrosoftTeams-image.png?v=1688537490084',
    //   name:'Nguyễn Tuấn Vinh',
    //   time:currentdate.getHours()+':' + currentdate.getMinutes(),
    //   date:currentdate.getDate() + '/'+ (currentdate.getMonth()+1)  + '/' + currentdate.getFullYear(),
    //   content:text,
    // }
    // setCommentList(
    //   prev=>([...prev,cmt])
    // )
    PostComment(currentUser.id,productId,commentInp,rating,currentdate)
    setRating(prev=>"")
    setComInp(prev=>"")
  }
  

  // console.log(commentList);


  const handleRatingChange = (value) => {
    setRating(value);
    // setIsRatingValid(value > 0);
  };
  

  const [limitedCommentShow,setLimitCommentShow] = useState(DEFAULT_LIMIT_NUMBER_OF_COMMENTS)

  const handleIncreseLimitComment=()=>{
    if(commentList.length>=limitedCommentShow+DEFAULT_LIMIT_NUMBER_OF_COMMENTS){

      setLimitCommentShow(limitedCommentShow+DEFAULT_LIMIT_NUMBER_OF_COMMENTS);
    }else{
      setLimitCommentShow(commentList.length)
    }
  }

  const handleSetDefaultLimitComment=()=>{
    setLimitCommentShow(DEFAULT_LIMIT_NUMBER_OF_COMMENTS);
  }
// console.log(limitedCommentShow);
  return (
    <div className='comment-container'>
      <div className="comment-content-title">
        <hr/>
        <h1>Các đánh giá</h1>
        <hr/>
      </div>
      <div className='comment-content'>
        {
          currentUser &&
          <div className='write-comment-area'>
            
            <div className='write-comment-container'>
            
              <div className='user-avatar'>
              
                <img src={currentData?.avt} alt='user-avatar'></img>
                
              </div>
              
              <div className='input-box' >
                <textarea 
                  rows={3}
                  type='text' 
                  className='comment-input' 
                  placeholder='Bình luận của bạn....' 
                  v-model='newItem' 
                  value={commentInp}
                  onChange={(event)=>setComInp(event.target.value)}
                >
                </textarea>
                <div className='rating-box'>
                  <div className='rating-title'>
                    Đánh giá shop:
                  </div>
                  <div className='rating-container'>

                    <button 
                      className='start-rating' id='one-start'                 
                      onClick={() => handleRatingChange(1)}
                      // onMouseLeave={() => handleRatingChange(0)}
                    >
                      1 <AiFillStar style={rating>=1?{color: 'rgb(255, 241, 41)'}:{}}/>
                    </button>
                    <button 
                      className='start-rating' id='two-start' 
                      onClick={() => handleRatingChange(2)}
                      // onMouseLeave={() => handleRatingChange(0)}
                    >
                      2 <AiFillStar style={rating>=2?{color: 'rgb(255, 241, 41)'}:{}}/>
                    </button>
                    <button 
                      className='start-rating' id='three-start'
                      onClick={() => handleRatingChange(3)}
                      // onMouseLeave={() => handleRatingChange(0)}
                    >
                      3 <AiFillStar style={rating>=3?{color: 'rgb(255, 241, 41)'}:{}}/>
                    </button>
                    <button 
                      className='start-rating' id='four-start'
                      onClick={() => handleRatingChange(4)}
                      // onMouseLeave={() => handleRatingChange(0)}
                    >
                      4 <AiFillStar style={rating>=4?{color: 'rgb(255, 241, 41)'}:{}}/>
                    </button>
                    <button 
                      className='start-rating' id='five-start'
                      onClick={() => handleRatingChange(5)}
                      // onMouseLeave={() => handleRatingChange(0)}
                    >
                      5 <AiFillStar style={rating>=5?{color: 'rgb(255, 241, 41)'}:{}}/>
                    </button>
                  </div>
                  
                </div>
              </div>
              <div className='submit-cmt-btn'>
                <button onClick={()=>handlePostComment(commentInp)} disabled={!rating}>Gửi</button>
              </div>
              
              
            </div>
            
          </div>       
        }
        
        <div className='display-comment-area'>
          <div className='title'>
            <hr/>
            <h3>Có {commentList?commentList?.filter(item=>item.productId===productId).length:"..."} đánh giá khác trong sản phẩm này</h3>
            <hr/>
          </div>
          <div className='other-comments-wrapper'>

            {commentList?.length>0?
              commentList.filter((item,index)=>index<limitedCommentShow && item.productId===productId).map(cmt=>{
                // console.log(cmt)
                return (

                  <PersonalComment 
                    avt_src ={cmt.avt_src} 
                    name ={cmt.users.fullname}
                    content ={cmt.text}
                    rating ={cmt.rating}
                    time_posting ={cmt.createdAt}
                    date_posting={cmt.date}
                  />

                )
              })
              :
              "Chưa có đánh giá nào"
            }
          
          </div>

          <div className='view-more-comments-btn'>
            <button
              onClick={limitedCommentShow>=commentList?.length?handleSetDefaultLimitComment:handleIncreseLimitComment}
            >
              {
                limitedCommentShow>=commentList?.length?
                "Ẩn bớt các đánh giá"
                :
                `- Xem thêm ${commentList?.length>=limitedCommentShow+DEFAULT_LIMIT_NUMBER_OF_COMMENTS?DEFAULT_LIMIT_NUMBER_OF_COMMENTS:commentList?.length-limitedCommentShow} đánh giá khác -`
              }
            </button>
          </div>

        </div>
      </div>
    </div>  
  
  )

}

export default CommentsArea
