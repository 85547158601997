import React,{useState,useEffect} from 'react';
import '../styles/footer.scss';

import GetAllContacts from '../controllers/ContactController';

import { BsFacebook,BsTiktok,BsYoutube, BsTelephone } from 'react-icons/bs';
import { BiMailSend } from 'react-icons/bi';

const Shipping_unit=[
  {
    src:"https://viettelpost.com.vn/wp-content/uploads/2020/03/logo-380x270.jpg",
    alt:"Viettel Post",
  },
  {
    src:"https://giaohangtietkiem.vn/wp-content/themes/giaohangtk/images/logo-header.png",
    alt:"Giaohangtietkiem.vn - Dịch vụ giao hàng trong ngày chuyên nghiệp",
  },
  {
    src:"https://jtexpress.vn/themes/jtexpress/assets/images/logo.svg",
    alt:"J&T Express - Giao hàng Chuyển phát nhanh",
  },
  {
    src:"https://cdn.shopify.com/app-store/listing_images/db01b7c7ac4a4d996b2f394ada888832/banner/CIOdk9T0lu8CEAE=.png",
    alt:"Ninja Van",
  },
  {
    src:"https://nascoexpress.com/Uploads/DigitalSystem/8f/8fa61808-8d69-4dcb-8f23-76a66998be16.png",
    alt:"NASCO EXPRESS - CÔNG TY CỔ PHẦN GIAO NHẬN HÀNG HOÁ NASCO",
  }
  
]

const Payment=[
  {
    src:"https://media.techcombank.com/uploads/techcombank_logo_svg_86201e50d1.svg?w=1080&q=75",
    alt:"Techcombank - Ngân hàng TMCP Kỹ thương Việt Nam | Techcombank"
  },
  {
    src:"https://www.mbbank.com.vn/images/logo.png",
    alt:"MB NGÂN HÀNG QUÂN ĐỘI | MBBANK - MB-BANK"
  },
  {
    src:"https://lendy.vn/wp-content/uploads/2021/06/tpbank-1030x539.png",
    alt:"TPBank - Ngân hàng dẫn đầu Kỷ nguyên Số."
  },
  {
    src:"https://business.momo.vn/assets/landingpage/img/931b119cf710fb54746d5be0e258ac89-logo-momo.png",
    alt:"Momo"
  },

]

function Footer() {

  const [contactLink,setContactLink]=useState([])
  useEffect(()=>{
    GetAllContacts(setContactLink);
  },[])
  return (
    <footer className="footer">
      <div className="footer__logo">
        <div>
          <img src="/img/logo/logo_lentilab-01.png" alt="Company Logo" />
        </div>
        <div>
          <h1>SHOP ẢNH NỔI</h1>
          {/* <h1>3D LENTICULAR</h1> */}
        </div>
      </div>

      <hr/>


      <div className='infoNlink'>
        <div className='liinfolink'>
          <h3>Dịch vụ</h3>
          <ul>
            {
              contactLink?.filter(item=>item.theme==="service")?.map((item,index)=>{
                return(

                  <li key={`service-${index}`}>{item.link}</li>
                )
              })
            }
            {/* <li>Chuyên in các loại card (card giấy C300, PVC, ...)</li>
            {/* <li>Chuye</li> */}
            {/* <li>Rửa ảnh online - chất lượng cao</li> */} 
          </ul>
        </div>

        <div className='liinfolink'>
          <h3>Đơn vị vận chuyển</h3>
          <ul >
            {
              Shipping_unit?.map((item,index)=>{
                return(

                  <li style={{width: "28%"}} key={`service-${index}`}>
                    <div className='service-logo'>
                      <img src={item.src} alt ={item.alt}></img>
                    </div>
                  </li>
                )
              })
            }
          </ul>
        </div>

        <div className='liinfolink'>
          <h3>Thanh toán</h3>
          <ul>
            {
              Payment?.map((item,index)=>{
                return(

                  <li style={{width: "28%"}} key={`service-${index}`}>
                    <div className='service-logo'>
                      <img src={item.src} alt ={item.alt}></img>
                    </div>
                  </li>
                )
              })
            }
          </ul>
        </div>

        <div className='liinfolink'>
          <h3>Thông tin</h3>
          <ul>
            {
              contactLink?.filter(item=>item.theme==="store infor")?.map((item,index)=>{
                return(
                  <li key={`store-infor-${index}`}>{item.title}: {item.link}</li>
                )
              })
            }
          </ul>
        </div>
        <div className='liinfolink'>
          <h3>Liên hệ</h3>
          <ul>

            {
              contactLink?.filter(item=>item.theme==="contact")?.map((item,index)=>{
                if(item.title==="Email"){
                  return(
                    <li key={`Email-${index}`}><BiMailSend/> Email: {item.link}</li>
                  )
                }else if(item.title==="Hotline")
                  return(

                    <li key={`Hotline-${index}`}><BsTelephone/> Hotline: {item.link}</li>
                  )
              })
            }
            
          </ul>
        </div>
        <div className='liinfolink'>
          <h3>Trang cộng đồng</h3>
          <ul className='contactIcon'>
            <li key={`contactIcon-${1}`}>
              <a href="https://www.facebook.com/chuyeninanh3dlenticular" 
              target="_blank" rel="noopener noreferrer">
                <BsFacebook className='icon'/> <p>Shop Ảnh nổi 3D Lenticular</p>
              </a>
            </li>
            <li key={`contactIcon-${2}`}>
              <a href="https://www.tiktok.com/@lenti_lab"
                target="_blank" rel="noopener noreferrer">
                  <BsTiktok className='icon'/> <p>@lenti_lab</p>
              </a>
            </li>
            {/* <li>
              <a 
                href="/"
                target="_blank" rel="noopener noreferrer">
                  <BsInstagram className='icon'/>
              </a>
            </li> */}
            <li key={`contactIcon-${3}`}>
              <a 
                href="https://www.youtube.com/@3dlenticularvn296"
                target="_blank" rel="noopener noreferrer">
                <BsYoutube className='icon'/> <p>3D lenticularvn</p>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
