import instance from "../../axiosConfig";

export async function GetAllPriceTables(setDataFunc) {
  // const url =process.env.REACT_APP_GET_ALL_PRICE_TABLES
  const url =process.env.REACT_APP_GET_ALL_PRICE_TABLES
  try {
    const response = await instance.get(url); // Sử dụng instance đã tạo
    // console.log(response.data.data);
    setDataFunc(response.data.data);
  } catch (error) {
    console.error(error);
  }
}