// import { GetToken} from "./TokenController";
import instance from "../../axiosConfig";

export async function GetAllUser(setDataFunc){
  const url =process.env.REACT_APP_GET_ALL_USER+"?pageSize=10000"
  // console.log(url);
  try {
    const response = await instance.get(url); // Sử dụng instance đã tạo
    setDataFunc(response.data.data);
  } catch (error) {
    console.error(error);
  }
}

export async function GetUserById(id,setDataFunc){
    const url =process.env.REACT_APP_GET_USER_BY_ID+`${id}`
    // console.log(url);
    try {
      const response = await instance.get(url); // Sử dụng instance đã tạo
      setDataFunc(response.data.data);
    } catch (error) {
      console.error(error);
    }
}