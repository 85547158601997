import supabase from "./config/supabaseClient";

export const getall=async()=>{
   
    
    let { data: comments, error } = await supabase
    .from('comments')
    .select(`*,
    
      users(
        fullname,
        images(
          imgSrc
        )
      )
    `)


  if(error){
    console.log("Error fetching all the comments", error)
    return null;
  }else {
      
    return comments
  }

} 

export const postcomment=async(userId,productId,content,rating,date)=>{
  
  const { data: comment, error } = await supabase
  .from('comments')
  .insert(
    { 
      userId: userId, 
      productId: productId ,
      text: content,
      createdAt:date,
      rating:rating
    },
  )

}
  