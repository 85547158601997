import instance from "../../axiosConfig";
async function GetAllContacts(setDataFunc) {
  const url =process.env.REACT_APP_GET_ALL_CONTACTS+"?pageSize=10000"
  try {
    const response = await instance.get(url); // Sử dụng instance đã tạo
    setDataFunc(response.data.data);
  } catch (error) {
    console.error(error);
  }
}
export default GetAllContacts