import React,{useContext} from "react";
import PriceTableTable from "./PriceTable";

import { AppContext } from "../../Context/AppProvider";

export default function ProductOptionRender({size, productId}){
    let currentPriceTables;
    const {priceTables} = useContext(AppContext)

    // console.log(priceTables);

    if (!priceTables || !priceTables.length) {
        // Display a loading indicator or handle the absence of data
        return <div>Loading Price Tables...</div>;
    }else{
        currentPriceTables=priceTables?.filter(priceTable=>priceTable.product.id===+productId)
    }

    // console.log(productId)
    return (
        <div className="product-options-content">
            <div className="title">
                <p>Bảng giá với kích thước: </p>
            </div>
            <div className="product-option-container">

                <div className="unit-price-container">
                    {currentPriceTables?.filter(priceTable=>priceTable.size!==size).map((priceTable,index)=>{
                        return(

                            <PriceTableTable priceTable={priceTable} key={index}/>
                        )
                    })}
                        
                </div>

            </div>
        </div>        

           
    )
}