import React, { useState,useEffect, useContext } from "react";
import '../styles/config/content.scss'
import '../styles/account.scss'

import RenderUserProfile from "../components-render/RenderUserProfile";
import RenderPasswordChanging from "../components-render/RenderPasswordChanging";
import { GetUserById } from "../controllers/UserController";
import {AppContext} from "../../Context/AppProvider"

function Account() {
    // const {currentData}= useSelector(state=>state.user)
    let CurrentUser={}
    const [ userState,setUserState] = useState(CurrentUser)
    const {users}= useContext(AppContext)

    const currentURL = window.location.href
    const user_id = currentURL.match(/userId=(\d+)/)[1];
    
    if(!users || !users?.length){
        return <div></div>
    }else{
        CurrentUser=users?.find((user)=>user.id===+user_id)
        // setUserState(CurrentUser)
    }
    // console.log(CurrentUser)
    return (

        <div className="main">
            <div className="account-content">

                <div className="title-account">
                    <p>THÔNG TIN TÀI KHOẢN CỦA BẠN</p>
                    <hr></hr>
                </div>
                <RenderUserProfile userInfor={CurrentUser} setUserState={setUserState}/>
                <RenderPasswordChanging/>

            </div>
        </div>

    )
}

export default Account;