import instance from "../../axiosConfig";

async function GetAllSlides(setDataFunc) {
  const url =process.env.REACT_APP_GET_ALL_IMAGE_SLIDERS
  try {
    const response = await instance.get(url); // Sử dụng instance đã tạo
    setDataFunc(response.data.data);
  } catch (error) {
    console.error(error);
  }
}
export default GetAllSlides