import React, { createContext, useEffect, useState } from 'react';
import { GetAllProductCategorys, GetAllProducts } from '../components/controllers/ProductContoller';
import { GetAllPriceTables } from '../components/controllers/PriceTableController';
import GetAllHotProducts from '../components/controllers/HotProductController';
import GetAllContacts from '../components/controllers/ContactController';
import { GetAllUser } from '../components/controllers/UserController';

export const AppContext = createContext();

export const AppProvider = ({ children }) => {
  
  const [products, setProducts] = useState([]);
  const [priceTables, setPriceTables]= useState([]);
  const [hotProducts, setHotProducts] = useState([]);
  const [productCategories,setProductCategories]= useState([])
  const [shopProfile,setShopProfile] = useState([])
  const [users,setUser]= useState([])

  const [categoryFilterSelected, setCategoryFilterSelected] = useState(""); //For filter in Home page

  useEffect(() => {
    GetAllProducts(setProducts);
    GetAllProductCategorys(setProductCategories);
    GetAllPriceTables(setPriceTables);
    GetAllHotProducts(setHotProducts);
    GetAllContacts(setShopProfile);
    GetAllUser(setUser)
  }, []);

  // console.log("in appContext: ", {productCategories});
  return (
    <AppContext.Provider
      value={{ products, priceTables,hotProducts,productCategories, shopProfile,users,categoryFilterSelected, setCategoryFilterSelected}}
    >
      {children}
    </AppContext.Provider>
  );
};