// import getall from "../models/TextSliderModel";

import instance from "../../axiosConfig";

async function GetAllTexts(setDataFunc) {
  const url =process.env.REACT_APP_GET_ALL_TEXT_SLIDER
  try {
    const response = await instance.get(url); // Sử dụng instance đã tạo
    setDataFunc(response.data.data);
  } catch (error) {
    console.error(error);
  }
}
export default GetAllTexts