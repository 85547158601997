import instance from "../../axiosConfig";
import { GetToken } from "./TokenController";

export async function GetAllOrder(setDataFunc) {
  const url =process.env.REACT_APP_GET_ALL_ORDERS
  try {
      const response =await instance.get(url)
      setDataFunc(response.data.data)

  } catch (error) {
    // Handle any errors that may occur during the promise execution
    console.error(error);
  }
}

export async function AddNewOrder(data,userId){

    try{
        const url =process.env.REACT_APP_POST_ORDER_BY_USER_ID_API+`=${userId}`
        // console.log(url);
        const token= GetToken("persist:auth")
        // console.log(token);
        const headers = {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json', // Set the content type based on your request
        };
        const response = await fetch(url, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(data),
        });

        if (response.ok) {
        // Request was successful
        // const data = await response.json();
        // console.log(data);
        return 1
      } else {
        // Handle the error condition
        console.log('Error:', response.status);
      }
    } catch (error) {
      // Handle network or other errors
      console.error('Error:', error);
    }
}