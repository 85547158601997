import React from 'react'; 

import './component_styles/personalComment.scss'
import {AiFillStar} from 'react-icons/ai'

export default function PersonalComment({avt_src, name, content, rating, time_posting, date_posting}){
    // console.log(avt_src,' ' ,name,' ' , content,' ' , rating,' ' , time_posting,' ' , date_posting);

  return(
  
    <div className='comment-idex-container'>
      
      <div className='infor-bar'>
        <div className='user-infor'>
          <div className='avatar-idx'>
            <img src={avt_src} alt='avatar'></img>
          </div>

          <div className='user-name'>
            <p>
              {name}
            </p>
          </div>
        </div>

        <div className='rated-point'>
          <b>{rating}</b>/5 <AiFillStar/>
        </div>
      
      </div>
     
      <div className='cmt-content'>

        <p>
          {content?content:"..."}
        </p>
      
      </div>      
      <div className='cmt-time'>
        <p>Sent: {time_posting} - {date_posting}</p>
      </div>
    
    </div>
  )
}