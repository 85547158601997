import React,{useEffect, useState} from "react";
import { Link, useLocation} from "react-router-dom";

import { GetDecodedToken } from "../controllers/TokenController";
import { AddNewCart } from "../controllers/CartController";
import Swal from "sweetalert2";

const basicParameter = [
    { paramsName: "Màu sắc", value: "color" },
    { paramsName: "Hệ màu", value: "colorsys" },
    { paramsName: "Số mặt in", value: "sides" },
    { paramsName: "Kỹ thuật in", value: "pritech" },
    { paramsName: "Bo góc thẻ nhựa", value: "cut" },
    { paramsName: "Thời gian thành phẩm", value: "time" },
    { paramsName: "Loại ấn phẩm", value: "category" },
    { paramsName: "Chất liệu", value: "material" },
    { 
      paramsName: "Hiệu ứng",
      value: "effect"
    },
    { 
      paramsName: "Kích thước thành phẩm", 
      value: "size"
    },
    { 
      paramsName: "Bạn có file thiết kế chưa?", 
      value: [
        "Đã có",
        "Chưa có",
      ],
    },
      
  ];

export default function BasicParamsRender({product_infor}){

    const handleOrderClick = () => {
        localStorage.setItem('order', JSON.stringify([userSelected]));
    };
    const data= JSON.parse(localStorage.getItem("persist:auth"))
    const decodedToken = GetDecodedToken("persist:auth")
    // console.log(data);

    const handleCartClick = () => {
    // console.log(decodedToken);

        try {
            const final_post_order={
                userId:`${decodedToken?.userId}`,
                productList:[
                    {
                        name:product_infor.name,
                        productId:product_infor.id,
                        material:userSelected.material,
                        size:userSelected.size,
                        effect:userSelected.effect,
                        quantity:`${userSelected.quantity}`,
                        isDesigned:userSelected.isDesigned,
                        img_src:userSelected.img_src,
                        sides:userSelected.sides,
                        price:5000,
                    }
                ],
            }
            // console.log(final_post_order);
            if (data.isLoggedIn==="true") {
                
                if(AddNewCart(final_post_order, decodedToken?.userId)){
                    Swal.fire("Đã thêm vào giỏ hàng của bạn")
                }
            }
        // window.location.href = `/carts?userId=${decodedToken?.userId}`;
        } catch (error) {
            // Handle network or other errors
            console.error('Error:', error);
        }
    };
    
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
      
    const [userSelected,setUserSelection]= useState({
        productId:product_infor?.id,
        name:product_infor?.name,
        material:searchParams.get('material')?searchParams.get('material'):product_infor?.[basicParameter[7].value]?.split(", ")[0],
        sides:searchParams.get('sides')?searchParams.get('sides'):product_infor?.[basicParameter[2].value]?.split(", ")[0],
        effect:searchParams.get('effect')?searchParams.get('effect'):product_infor?.[basicParameter[8].value]?.split(", ")[0],
        size:searchParams.get('size')?searchParams.get('size'):product_infor?.[basicParameter[9].value]?.split(", ")[0],
        quantity:0,
        isDesigned:searchParams.get('isDesigned')?searchParams.get('isDesigned'):basicParameter[10].value[0],
        img_src:""
    })
    
    // useEffect(()=>{
    //     setUserSelection({
    //         productId:product_infor?.id,
    //         name:product_infor?.name,
    //         material:product_infor?.[basicParameter[7].value]?.split(", ")[0],
    //         sides:product_infor?.[basicParameter[2].value]?.split(", ")[0],
    //         effect:product_infor?.[basicParameter[8].value]?.split(", ")[0],
    //         size:product_infor?.[basicParameter[9].value]?.split(", ")[0],
    //         quantity:0,
    //         isDesigned:basicParameter[10].value[0],
    //         img_src:""
    //     })
    // },[product_infor])
    

    const handleSetUserSelection=(attribute,value)=>{
        setUserSelection((prevState) => ({
            ...prevState,
            [attribute]: value,
        }));
    }

    // url hiện tại chứa tham số là category và productId
    const unidecode =require('unidecode') //Bỏ dấu trong tiếng Việt
    const currentUrl = `?category=${unidecode(product_infor?.category?.toLowerCase()?.replace(/\s+/g, '')||"none")?.replace(/[^a-zA-Z0-9]+/g, '')}&productId=${product_infor?.id}`

    // console.log(currentUrl);

    return (
        <div className="basic-params-content">
            <div className='product-name'>
                <h1 className='name'>
                    {product_infor?.name}
                </h1>
                <h4 className='sub-name'>
                    {product_infor?.name2}
                </h4>
            </div>
            
            <div className="params-container">
                <div className="title">
                    <h3>Thông số cơ bản:</h3>
                </div>
                <div className="params-item-box">

                    {basicParameter.map((params,index)=>{
                        return(
                            <div className="basic-params-item">
                                <div className="basic-params-title">
                                    {/* <p>{params.paramsName}: </p> */}
                                    {
                                        product_infor?.name==="Sticker"&&
                                        params.paramsName==="Chất liệu" &&
                                        <h4>Kiểu thành phẩm: </h4>
                                    }
                                                                        {
                                        product_infor?.name==="Sticker"&&
                                        params.paramsName!=="Chất liệu" &&
                                        <h4>{params.paramsName}:  </h4>
                                    }
                                    {
                                        product_infor?.name!=="Sticker"&&
                                        <h4>{params.paramsName}: </h4>
                                    }
                                </div>
                                
                                {
                                    params.paramsName==='Hiệu ứng'&&
                                    
                                    <div key={index} className="ef-size-params-value">
                                        {
                                            product_infor?.[params.value]?.split(', ').map(item=>{
                                                return(

                                                    <Link 
                                                        to={`${currentUrl}&sides=${userSelected.sides}&material=${userSelected.material}&effect=${item}&size=${userSelected.size}&isDesigned=${userSelected.isDesigned}`}
                                                        // to={url}
                                                        onClick={()=>handleSetUserSelection('effect',item)}
                                                        className={userSelected?.['effect']===item?'option-active':'option'}
                                                    >   
                                                        {item==="non"?"Không có":item}
                                                    </Link>
                                                )
                                            })
                                        }
                                    </div>
                                }

{
                                    params.paramsName==='Số mặt in'&&
                                    
                                    <div key={index} className="ef-size-params-value">
                                        {
                                            product_infor?.[params.value]?.split(', ').map(item=>{
                                                return(

                                                    <Link 
                                                        to={`${currentUrl}&sides=${item}&material=${userSelected.material}&effect=${userSelected.effect}&size=${userSelected.size}&isDesigned=${userSelected.isDesigned}`}
                                                        className={userSelected['sides']===item?'option-active':'option'}
                                                        onClick={()=>handleSetUserSelection('sides',item)}
                                                    >{item==="non"?"Không có":item}</Link>
                                                )
                                            })
                                        }
                                    </div>
                                }

                                {
                                    params.paramsName==='Bạn có file thiết kế chưa?'&&
                                    
                                    <div key={index} className="ef-size-params-value">
                                        {
                                            params.value.map(item=>{
                                                return(

                                                    <Link 
                                                        to={`${currentUrl}&sides=${userSelected.sides}&material=${userSelected.material}&effect=${userSelected.effect}&size=${userSelected.size}&isDesigned=${item}`}
                                                        className={userSelected['isDesigned']===item?'option-active':'option'}
                                                        onClick={()=>handleSetUserSelection('isDesigned',item)}
                                                    >{item}</Link>
                                                )
                                            })
                                        }
                                    </div>
                                }

                                {params.paramsName === 'Chất liệu' && 
                                    <div key={index} className="ef-size-params-value">
                                        {product_infor?.[params.value]?.split(', ').map(item => (
                                        <Link
                                            to={`${currentUrl}&sides=${userSelected.sides}&material=${item}&effect=${userSelected.effect}&size=${userSelected.size}&isDesigned=${userSelected.isDesigned}`}
                                            className={userSelected['material'] === item ? 'option-active' : 'option'}
                                            onClick={() => handleSetUserSelection('material', item)}
                                        >
                                            {item==="non"?"Không có":item}
                                        </Link>
                                        ))}
                                    </div>
                                }


                                {
                                    params.paramsName==='Kích thước thành phẩm'&&
                                    
                                    <div key={index} className="ef-size-params-value">
                                        {
                                            product_infor?.[params.value]?.split(', ').map(item=>{
                                                return(

                                                    <Link 
                                                        to={`${currentUrl}&sides=${userSelected.sides}&material=${userSelected.material}&effect=${userSelected.effect}&size=${item}&isDesigned=${userSelected.isDesigned}`}
                                                        className={userSelected['size']===item?'option-active':'option'}
                                                        onClick={()=>handleSetUserSelection('size',item)}
                                                    >{item==="non"?"Không có":item}</Link>
                                                )
                                            })
                                        }
                                    </div>
                                }

                                {
                                    params.paramsName!=='Hiệu ứng' 
                                    && params.paramsName!=='Kích thước thành phẩm'
                                    &&  params.paramsName!=='Chất liệu' 
                                    && params.paramsName!=='Kiểu thành phẩm'
                                    && params.paramsName!=='Bạn có file thiết kế chưa?'
                                    && params.paramsName!=='Số mặt in'&&(
                                    <div key={index} className="basic-params-value">
                                        {/* {console.log(params.value)} */}
                                        {product_infor?.[params.value]?.split(', ').map(item=>{
                                            // console.log(item);
                                            return(

                                                <h4 className="option">{item}</h4>
                                            )
                                        })}
                                    </div>
                                )}

                           
                            </div>    
                        )
                    })}
                    <div className="basic-params-item">
                        <div className="basic-params-title">
                            <h4>Số lượng: </h4>
                        </div>
                        <div className="basic-params-input">
                            <input 
                                type="text"
                                className="img-src-link-input"
                                placeholder="Nhập số lượng vào đây"
                                name="img-src-link-input"
                                value={userSelected.quantity}
                                onChange={(e)=>setUserSelection(prev=>({...prev,quantity:e.target.value}))}
                                
                            />
                        </div>
                    </div>
                    <div className="basic-params-item">
                        <div className="basic-params-title">
                            <h4>Link ảnh: </h4>
                        </div>
                        <div className="basic-params-input">
                            <input 
                                type="text"
                                className="img-src-link-input"
                                placeholder="Bỏ link ảnh vào đây"
                                name="img-src-link-input"
                                value={userSelected.img_src}
                                onChange={(e)=>setUserSelection(prev=>({...prev,img_src:e.target.value}))}
                                required
                            />
                        </div>
                    </div>
                </div>

                <div className="order-btns">
                    <button className="btns" onClick={handleOrderClick}>
                        <Link to={data.isLoggedIn==="true"?
                                    `/orderConfirmation?name=${product_infor.name}&productId=${product_infor.id}`
                                    :"/login"
                                    }>
                        
                            ĐẶT IN NGAY
                        </Link>

                    </button>
                    <button className="btns" onClick={handleCartClick}>
                        <Link to={data.isLoggedIn==="true"?
                                    `/`
                                    :"/login"
                                    }>
                        
                            Thêm vào giỏ hàng
                        </Link>                

                    </button>
                </div>    

            </div>
            
        </div>
    )
}